import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {connect} from "react-redux";
import {
    getDrivers,
    getDriversCount, getDriversInterest, getDriversInterestCount,
    getDriversInvited,
    getDriversInvitedCount,
    getRequestedDrivers, getRequestedDriversCount
} from "../../actions/driversAction";
import React, {useEffect, useState} from "react";
import Pagination from "react-js-pagination";
import {NavLink, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd} from "@fortawesome/free-solid-svg-icons";


const TableData = ({drivers}) => {
    return (
        <div className='px-3'>
            <div className="table-responsive" style={{minHeight: "50vh"}}>
                <Table>
                    <TableHead>
                        <TableRow style={{background: '#DCFFFD'}} hover>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            {/*<TableCell>Email</TableCell>*/}
                            {/*<TableCell>Phone Number</TableCell>*/}
                            {/*<TableCell>Driver Status</TableCell>*/}
                            {/*<TableCell>Partner Status</TableCell>*/}
                            {/*<TableCell>Action</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {drivers?.length > 0 && drivers.map((driver, key) => (
                                <TableRow className='bg-white' hover key={key}>
                                    <TableCell>{driver?.user_data?.first_name}</TableCell>
                                    <TableCell>{driver?.user_data?.last_name}</TableCell>
                                    {/*<TableCell>{driver?.user_data?.email}</TableCell>*/}
                                    {/*<TableCell>{driver?.user_data?.phone_number}</TableCell>*/}
                                    {/*<TableCell>*/}
                                    {/*    <div className='p-1' style={{color: '#0B132A', background: driver?.driver_status === 2 ? '#26CF92' : '#FFF2CF', width: 'fit-content', borderRadius: '5px'}}>{driver?.driver_status === 2 ? 'Approved' : 'Pending'}</div>*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell>*/}
                                    {/*    <div className='p-1' style={{color: '#0B132A', background: driver?.partner_status === 1 ? '#26CF92' : '#FFF2CF', width: 'fit-content', borderRadius: '5px'}}>{driver?.partnership_status === 1 ? 'Approved' : 'Pending'}</div>*/}
                                    {/*</TableCell>*/}
                                    {/*<TableCell>*/}
                                    {/*    <NavLink to={`/drivers/${driver?.user_data?.auth_id}`}>*/}
                                    {/*        <button style={{background: '#2096D9', border: '1px solid #2096D9'}} className='btn btn-primary'>*/}
                                    {/*            View*/}
                                    {/*        </button>*/}
                                    {/*        /!*<FontAwesomeIcon role='button'  className="sidebarIcon text-info" icon={faEye} />*!/*/}

                                    {/*    </NavLink>*/}
                                    {/*</TableCell>*/}
                                </TableRow>
                            ))}
                        </>
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}


const GridData = ({drivers}) => {
    return (
        <div className='row px-3'>
            {drivers?.length > 0 && drivers.map((driver, index) => (
                <div className='col-sm-3 mt-4' key={index}>
                    <NavLink style={{textDecoration: 'none'}} to={`/drivers/${driver.auth_id}`}>
                    <div className='gridCard' style={{background: 'white', borderRadius: '5px', display: 'block'}}>
                        <div style={{background: '#E5E5E5', borderRadius: '5px 5px 0 0'}} className='d-flex align-items-center justify-content-center w-100'>
                            <img src={driver?.user_data?.avatar ? driver?.user_data?.avatar : require('../../assets/images/avatar2.png')} alt="" style={{width: '125px', height: '125px', borderRadius: driver?.user_data?.avatar ? '50%' : ''}}  />
                        </div>
                        <div className='mt-2 p-2' style={{overflowWrap: 'break-word'}}>
                            <div className='mt-2 fw-bold text-dark' style={{fontSize: '20px'}}>{driver?.user_data?.first_name} {driver?.user_data?.last_name}</div>
                            <div className='mt-2' style={{color: '#0F3042', fontSize: '12px'}}>Email: {driver?.user_data?.email}</div>
                            <div className='mt-2' style={{color: '#0F3042', fontSize: '12px'}}>Tel: {driver?.user_data?.phone_number}</div>
                            <div className='mt-2 p-1' style={{color: '#0B132A', fontSize: '14px', background: driver?.driver_status === 2 ? '#26CF92' : '#FFF2CF', width: 'fit-content', borderRadius: '5px'}}>{driver?.driver_status === 2 ? 'Approved' : 'Pending'}</div>
                        </div>
                    </div>
                    </NavLink>
                </div>
            ))}

        </div>
    )
}


const  Drivers = (props) => {

    const {
        loading,
        drivers,
        getDrivers,
        driversCount,
        getDriversCount,
        getDriversInvited,
        getDriversInvitedCount,
        getRequestedDrivers,
        getRequestedDriversCount,
        getDriversInterest,
        getDriversInterestCount,
    } = props

    const  navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [isTableData, setIsTableDate] = useState(true)
    const [type, setType] = useState('approved')

    useEffect(() => {
        handleFilter()
    },[])

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        getDrivers(pageNumber);
    };

    const handleFilter = () => {
        setCurrentPage(1)
        if(type === 'approved') {
            getDrivers();
            getDriversCount();
        } else if(type === 'invited') {
            getDriversInvited()
            getDriversInvitedCount()
        } else if(type === 'papplicant') {
            getRequestedDrivers()
            getRequestedDriversCount()
        }else if(type === 'gapplicant') {
            getDriversInterest()
            getDriversInterestCount()
        }
    }



    return (
      <div className="table-responsive" style={{minHeight: "50vh"}}>
          <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3 px-3'>Drivers</div>
          {/*<div className='w-100 p-2 d-flex '>*/}
          {/*    <div className="form-group px-2">*/}
          {/*       <div className='d-flex'>*/}
          {/*           <select value={type}  className='form-select selectInput'   onChange={(e) =>  setType(e.target.value)} required style={{width: 'fit-content'}}>*/}
          {/*               <option value="approved">Approved</option>*/}
          {/*               <option value="invited">Invited</option>*/}
          {/*               /!*<option value="pending">Pending</option>*!/*/}
          {/*               <option value="papplicant">Personal Applicant</option>*/}
          {/*               <option value="gapplicant">General Applicant</option>*/}
          {/*           </select>*/}
          {/*           <button onClick={() => handleFilter()} type="button" className="btn btn-success" style={{marginLeft: '10px'}}>*/}
          {/*               Apply*/}
          {/*           </button>*/}
          {/*       </div>*/}
          {/*    </div>*/}
          {/*    <div className='d-flex align-items-center justify-content-end w-100'>*/}
          {/*        <button onClick={() => navigate('/drivers/invites')} type="button" className="btn btn-primary  d-flex align-items-center justify-content-center appBg-button" style={{marginRight: '20px'}}>*/}
          {/*            <FontAwesomeIcon className="sidebarIcon" icon={faAdd} />*/}
          {/*            Invite Driver*/}
          {/*        </button>*/}
          {/*        <div className='px-2'>*/}
          {/*            <img onClick={() => setIsTableDate(false)} role='button' src={require('../../assets/images/grid_icon.png')} alt="" style={{width: '25px', height: '25px'}}  />*/}
          {/*        </div>*/}
          {/*        <div className='px-2'>*/}
          {/*            <img onClick={() => setIsTableDate(true)} role='button' src={require('../../assets/images/list_icon.png')} alt="" style={{width: '22px', height: '22px'}}  />*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}

          {drivers.length > 0 ?
              <>
                  {isTableData && <TableData drivers={drivers} driversCount={driversCount} currentPage={currentPage} paginate={paginate} />}
                  {!isTableData && <GridData drivers={drivers} />}


                  <div className="d-flex justify-content-end align-items-center mb-0 mt-3 mr-2">
                      <Pagination activeLinkClass='info'  activePage={currentPage} itemClass="page-item" linkClass="page-link" itemsCountPerPage={20} totalItemsCount={driversCount} onChange={paginate} />
                  </div>
              </> :
              <div>
                  {!loading &&
                      <div className='text-center'>No drivers available</div>
                  }
              </div>
          }
      </div>
  );
}

function mapDispatchToProps(dispatch) {
    return {
        getDrivers: (page_no) => dispatch(getDrivers(page_no)),
        getDriversCount: () => dispatch(getDriversCount()),
        getDriversInvited: (page_no) => dispatch(getDriversInvited(page_no)),
        getDriversInvitedCount: () => dispatch(getDriversInvitedCount()),
        getRequestedDrivers: (page_no) => dispatch(getRequestedDrivers(page_no)),
        getRequestedDriversCount: () => dispatch(getRequestedDriversCount()),
        getDriversInterest: (page_no) => dispatch(getDriversInterest(page_no)),
        getDriversInterestCount: () => dispatch(getDriversInterestCount()),
    };
}

const mapStateToProps = (state) => ({
    drivers: state.drivers.drivers,
    driversCount: state.drivers.driversCount,
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Drivers);
