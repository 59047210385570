import axios from "axios";
import {NotificationManager} from "react-notifications";
import AppEndpoint from "../assets/utils/AppEndpoint";
import {VEHICLES, VEHICLES_COUNT, AVAILABLE_VEHICLES_COUNT} from "./types";
import {endLoading, startLoading} from "./loadingAction";
import {configureStore} from "../store";
import {getDriver} from "./driversAction";




export const getVehicles = (page_no = 1) => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.VehicleService}/vehicles?item_per_page=20&page=${page_no}`)
    if(res.data.status === 'ok') {
      dispatch({
        type: VEHICLES,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getVehiclesCount = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.VehicleService}/vehicles?component=count`)
    if(res.data.status === 'ok') {
      dispatch({
        type: VEHICLES_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}



export const assignVehicle = (data = {}, closeModal) => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.post(`${AppEndpoint.VehicleService}/vehicles/partner-assign-vehicle`, data)
    if(res.data.status === 'ok') {
      NotificationManager.success('vehicle assigned successfully')
      if(typeof closeModal === "function") {
        closeModal()
      }
     dispatch(getDriver(data?.driver_id))
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const revokeVehicle = (data = {}, closeModal) => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.post(`${AppEndpoint.VehicleService}/vehicles/partner-revoke-vehicle`, data)
    if(res.data.status === 'ok') {
      NotificationManager.success('vehicle revoked successfully')
      if(typeof closeModal === "function") {
        closeModal()
      }
      dispatch(getDriver(data?.driver_id))
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const retrieveAvailableVehicle = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.VehicleService}/vehicles/available-vehicles?assign=0`)
    if(res.data.status === 'ok') {
      dispatch({
        type: AVAILABLE_VEHICLES_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}



