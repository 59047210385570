import axios from "axios";
import {NotificationManager} from "react-notifications";
import AppEndpoint from "../assets/utils/AppEndpoint";
import {
  DRIVERS,
  DRIVERS_COUNT,
  DRIVER,
  DRIVER_RATING,
  DRIVERS_PAYOUT,
  DRIVERS_PAYOUT_COUNT,
  DRIVERS_INACTIVE_COUNT,
  SIGNUP_INFO
} from "./types";
import {endLoading, startLoading} from "./loadingAction";
import {configureStore} from "../store";




export const getDrivers = (page_no = 1, start_date='', end_date='') => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partner-drivers?item_per_page=20&page=${page_no}`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getDriversCount = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partner-drivers?component=count`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getDriversCountInactive = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partner-drivers?component=count`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const getDriver = (driver_id) => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partner-drivers?driver_id=${driver_id}`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVER,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getDriverRatingAverage = (auth_id) => async dispatch => {
  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())

  try {
    const res = await axios.get(`${AppEndpoint.RatingsService}/ratings?user_type=driver&auth_id=${auth_id}&component=avg`);
    if(res.data.status === 'error') {
      NotificationManager.error(res.data.msg);
    }else {
      dispatch({
        type: DRIVER_RATING,
        payload: res.data.data.avg ? res.data.data.avg : 0
      });
    }
    dispatch(endLoading())
  } catch (err) {
    dispatch(endLoading())
  }
};


export const partnerInviteDriver = (data, setInviteArray) => async dispatch => {
  // return console.log(data)
  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())

  try {
    const res = await axios.post(`${AppEndpoint.UserService}/users/partner-invite-drivers`, data)
    if(res.data.status === 'error') {
      NotificationManager.error(res.data.msg);
    }else {
     NotificationManager.success('Invitation sent successfully')
      if(typeof setInviteArray === "function") {
        setInviteArray([])
      }
    }
    dispatch(endLoading())
  } catch (err) {
    dispatch(endLoading())
    NotificationManager.error('Network error, try again');
  }
};




export const getDriversInvited = (page_no = 1, start_date, end_date) => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partner-invite-drivers?item_per_page=20&page=${page_no}`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getDriversInvitedCount = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partner-invite-drivers?component=count`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const getRequestedDrivers = (page_no = 1, start_date, end_date) => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partnership-request?item_per_page=20&page=${page_no}&status=0`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getRequestedDriversCount = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partnership-request?component=count&status=0`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getInactiveDriversCount = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partnership-request?component=count&status=0`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_INACTIVE_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const getDriversInterest = (page_no = 1, start_date, end_date) => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partnership-interest?item_per_page=20&page=${page_no}`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getDriversInterestCount = () => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.UserService}/users/partnership-interest?component=count`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}



export const acceptDriverInvite = (invite_id, status, driver_id) => async (dispatch) => {

  // 1=accept, 2=cancel, 3=reject

  // const body = {
  //   invite_id: driver_id,
  //   status
  // }

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.put(`${AppEndpoint.UserService}/users/partnership-request/${invite_id}`, {status})
    if(res.data.status === 'ok') {
     dispatch(getDriver(driver_id))
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const sendDriverRequest = (data) => async (dispatch) => {

  // 1=accept, 2=cancel, 3=reject


  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.post(`${AppEndpoint.UserService}/users/partnership-request`, data)
    if(res.data.status === 'ok') {
      dispatch(getDriver(data?.auth_id))
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const getDriverPayout = (page_no = 1, start_date = '', end_date = '', date_type='daily') => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/payout?item_per_page=20&page=${page_no}&date_type=${date_type}&start_date=${start_date}&end_date=${end_date}`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_PAYOUT,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getDriverPayoutCount = (start_date = '', end_date = '', date_type='daily') => async (dispatch) => {

  //change env
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/payout?item_per_page=20&date_type=${date_type}&start_date=${start_date}&end_date=${end_date}&component=count`)
    if(res.data.status === 'ok') {
      dispatch({
        type: DRIVERS_PAYOUT_COUNT,
        payload: res.data.data.total ? res.data.data.total : 0,
      });

    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const updateDriverPayoutMethod = (data) => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
 const  body =  {partner_driver_payment : data}

  dispatch(startLoading())
  try {
    const res = await axios.put(AppEndpoint.CreateUser + userInfo?.auth_id, body)
    console.log(res.data, 'ssssssssssssss')
    dispatch(endLoading())
    if(res.data.status === 'ok') {
      // await  localStorage.setItem('ud', JSON.stringify(res.data.data))
      // dispatch(endLoading())
      // dispatch({
      //   type: SIGNUP_INFO,
      //   payload: res.data.data,
      // });
      NotificationManager.success('Updated successfully')
    } else {
      NotificationManager.error(res?.data?.msg)
    }
  }catch (e) {
    dispatch(endLoading())
  }
}




