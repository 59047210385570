import React, {useEffect, useRef, useState} from "react";
import {updateProfile} from "../../actions/authAction";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDashboard, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import OtpForm from "../../components/otpForm/OtpForm";
import Timer from "../../components/timer/Timer";
import {retrieveAvailableVehicle} from "../../actions/vehiclesAction";
import {bankList} from "../../constants/bankList";


const CompleteProfile = ({signup_info, handleUpdate, loading, retrieveAvailableVehicle, availableVehiclesCount}) => {
    const [formData, setFormData] = useState({
        first_name: signup_info?.first_name,
        last_name: signup_info?.last_name,
        home_address: signup_info?.home_address,
        state: signup_info?.state,
        occupation: signup_info?.occupation,
        nin_id: signup_info?.nin_id?.value,
        gender: signup_info?.gender,
        bank_name: signup_info?.bank_data?.bank_name,
        account_number: signup_info?.bank_data?.account_number,
        bank_code: signup_info?.bank_data?.bank_code,
        account_name: signup_info?.bank_data?.account_name,

    })
    const [next_kin, setNet_kin] = useState({
        name: signup_info?.partner_data?.next_kin?.name,
        phone_number: signup_info?.partner_data?.next_kin?.phone_number,
        email: signup_info?.partner_data?.next_kin?.email,
        relationship:"contact person"
    })
    const [partner_driver_payment, setPartner_driver_payment] = useState({payment_type:"percent", driver_payment: "" //number
    })
    const [orgName, setOrgName] = useState(`${signup_info?.first_name} ${signup_info?.last_name}`)
    const [imgUrl, setImgUrl] = useState({})
    const [CACDoc, setCACDo] = useState({})
    const ref = useRef(null)
    const [editProfileData, setEditProfileData] = useState({})

    const {last_name, first_name, occupation, gender, home_address, nin_id, state, bank_name, account_number, account_name} = formData

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setEditProfileData({ ...editProfileData, [e.target.name]: e.target.value });
    }



    const handleSubmit = () => {
        // if(Object.keys(editProfileData).length > 0) {
            handleUpdate(signup_info?.partner_data?.account_type === 'individual' ? editProfileData : {...editProfileData, next_kin: next_kin, first_name: orgName, last_name: orgName}, partner_driver_payment, imgUrl, CACDoc)
        // }
    }

   useEffect(() => {
      if(bank_name) {
          const index = bankList.findIndex((bank) => bank.name === bank_name)
          if(index !== -1) {
              setFormData({...formData, bank_code: bankList[index].code})
              setEditProfileData({...editProfileData, bank_code: bankList[index].code})
          }
      }
   },[bank_name])


    return (
        <div style={{color: '#747474'}} className='w-100 d-flex align-items-center justify-content-center p-sm-2'>
            <div className=' bg-white px-5 py-4 w-100'>
                <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3'>Update Profile</div>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit()
            }}>
                <div className='row'>
                   <div className='col-sm-6'>

                       {signup_info?.partner_data?.account_type === 'individual' &&
                           <>
                               <div className="form-group w-100 mr-1">
                                   <label style={{color: '#747474'}} className='mt-3 mb-1'>First Name</label>
                                   <input disabled={true} type="text" className="form-control"  placeholder="First Name" value={first_name}  name='first_name' onChange={onChange} required/>
                               </div>
                               <div className="form-group w-100 ml-1">
                                   <label style={{color: '#747474'}} className='mt-3 mb-1'>Last Name</label>
                                   <input disabled={true} type="text" className="form-control"  placeholder="Last Name" value={last_name}  name='last_name' onChange={onChange} required/>
                               </div>
                           </>
                       }
                       {signup_info?.partner_data?.account_type === 'organization' &&

                               <div className="form-group w-100 mr-1">
                                   <label style={{color: '#747474'}} className='mt-3 mb-1'>Organisation  Name</label>
                                   <input type="text" className="form-control"  placeholder="Organisation Name" value={orgName}   onChange={(e) => setOrgName(e.target.value)} required/>
                               </div>
                       }
                       <div className="form-group w-100 mr-1">
                           <label style={{color: '#747474'}} className='mt-3 mb-1'>{signup_info?.partner_data?.account_type === 'individual' ? 'Home Address' : 'Office Address'}</label>
                           <input type="text" className="form-control"  placeholder={signup_info?.partner_data?.account_type === 'individual' ? 'Enter Home Address' : 'Enter Office Address'} value={home_address}  name='home_address' onChange={onChange} required/>
                       </div>
                       <div className="form-group">
                           <label style={{color: '#747474'}} className='mt-3 mb-1'>Bank Name</label>
                           <select  className='form-select' value={bank_name} onChange={onChange} name='bank_name' required>
                               <option value="">Select Bank</option>
                               {bankList.map(bank => (
                                   <option key={bank.id} value={bank.name}>{bank.name}</option>
                               ))}
                           </select>
                       </div>
                       {/*<div className="form-group w-100 mr-1">*/}
                       {/*    <label style={{color: '#747474'}} className='mt-3 mb-1'>Bank Name</label>*/}
                       {/*    <input type="text" className="form-control"  placeholder="Bank Name" value={bank_name} name='bank_name'   onChange={onChange} required/>*/}
                       {/*</div>*/}
                       {/*<div className="form-group w-100 mr-1">*/}
                       {/*    <label style={{color: '#747474'}} className='mt-3 mb-1'>Account Name</label>*/}
                       {/*    <input type="text" className="form-control"  placeholder="Account Name" value={account_name} name='account_name'   onChange={onChange} required/>*/}
                       {/*</div>*/}
                       <div className="form-group w-100 mr-1">
                           <label style={{color: '#747474'}} className='mt-3 mb-1'>Account Number</label>
                           <input type="number" className="form-control"  placeholder="Account Number" value={account_number} name='account_number'   onChange={onChange} required/>
                       </div>
                       {/*{signup_info?.partner_data?.partner_status !== 4 &&*/}
                       {/*    <div className="form-group w-100 mr-1">*/}
                       {/*        <label style={{color: '#747474'}} className='mt-3 mb-1'>{`Number of Vehicles interested (${availableVehiclesCount} Vehicles available)`}</label>*/}
                       {/*        <input type="number" className="form-control"  placeholder="Number of Vehicles" value={vehicle_interested}  name='vehicle_interested' onChange={onChange} required/>*/}
                       {/*    </div>*/}
                       {/*}*/}

                           {/*<>*/}
                           {/*    <div className='mt-3 fw-bold' style={{fontSize: '20px'}}>Driver's Payment</div>*/}
                           {/*    <div className="form-group">*/}
                           {/*        <label style={{color: '#747474'}} className='mt-3 mb-1'>Payment type  <FontAwesomeIcon onClick={() => handleModalOpen()} role='button' className="sidebarIcon" icon={faInfoCircle} /></label>*/}
                           {/*        <select disabled={signup_info?.partner_data?.partner_status === 4}  className='form-select' value={partner_driver_payment.payment_type} onChange={(e) => setPartner_driver_payment({...partner_driver_payment, payment_type: e.target.value})}  required>*/}
                           {/*            <option value="percent">Percent</option>*/}
                           {/*            <option value="fixed">Salary</option>*/}
                           {/*        </select>*/}
                           {/*    </div>*/}
                           {/*    <div className="form-group w-100 mr-1">*/}
                           {/*        <label style={{color: '#747474'}} className='mt-3 mb-1'>{partner_driver_payment.payment_type === 'percent' ? 'Percent to be paid' : 'Amount to be paid'}</label>*/}
                           {/*        <input disabled={signup_info?.partner_data?.partner_status === 4} type="number" className="form-control"  placeholder="Amount to be paid to driver" value={partner_driver_payment.driver_payment}  onChange={(e) => setPartner_driver_payment({...partner_driver_payment, driver_payment: e.target.value})}  required/>*/}
                           {/*    </div>*/}
                           {/*</>*/}
                       <div className="form-group w-100 mr-1">
                           <label style={{color: '#747474'}} className='mt-3 mb-1'>Profile Image</label>
                           <div>
                               <input type="file"
                                      id="avatar" name="avatar"
                                      accept="image/png, image/jpeg"
                                      onChange={(e) => setImgUrl(e.target.files[0])}

                               />
                           </div>
                       </div>
                   </div>
                    <div className='col-sm-6'>
                        {signup_info?.partner_data?.account_type === 'individual' &&
                           <>
                               <div className="form-group">
                                   <label style={{color: '#747474'}} className='mt-3 mb-1'>Gender</label>
                                   <select  className='form-select' value={gender}  name='gender' onChange={onChange} required>
                                       <option value=''>Select gender</option>
                                       <option value="male">Male</option>
                                       <option value="female">Female</option>
                                   </select>
                               </div>
                               <div className="form-group w-100 mr-1">
                                   <label style={{color: '#747474'}} className='mt-3 mb-1'>NIN</label>
                                   <input disabled={signup_info?.nin_id.status} type="text" className="form-control"  placeholder="NIN" value={nin_id}  name='nin_id' onChange={onChange} required/>
                               </div>
                               <div className="form-group">
                                   <label style={{color: '#747474'}} className='mt-3 mb-1'>Occupation</label>
                                   <input type="text" className="form-control"  placeholder="Occupation" value={occupation}  name='occupation' onChange={onChange} required/>

                               </div>
                               <div className="form-group w-100 mr-1">
                                   <label style={{color: '#747474'}} className='mt-3 mb-1'>State of origin</label>
                                   <input type="text" className="form-control"  placeholder="State of origin"  value={state}  name='state' onChange={onChange} required/>
                               </div>
                           </>
                        }

                        {signup_info?.partner_data?.account_type === 'organization' &&
                            <>
                                <div className="form-group w-100 mr-1">
                                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Contact person name</label>
                                    <input type="text" className="form-control"  placeholder="Contact person name" value={next_kin.name}   onChange={(e) => setNet_kin({...next_kin, name: e.target.value})} required/>
                                </div>
                                <div className="form-group">
                                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Contact person email</label>
                                    <input type="email" className="form-control"  placeholder="Contact person email" value={next_kin.email}   onChange={(e) => setNet_kin({...next_kin, email: e.target.value})} required/>

                                </div>
                                <div className="form-group w-100 mr-1">
                                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Contact person phone number</label>
                                    <input type="text" className="form-control"  placeholder="Contact person phone number"  value={next_kin.phone_number}   onChange={(e) => setNet_kin({...next_kin, phone_number: e.target.value})} required/>
                                </div>
                                <div className="form-group w-100 mr-1">
                                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Upload CAC document</label>
                                    <div>
                                        <input type="file"
                                               id="avatar" name="avatar"
                                               accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                               onChange={(e) => setCACDo(e.target.files[0])}

                                        />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <button disabled={loading} type="submit" className="btn btn-primary w-50 mt-5 appBg-button">Submit</button>
                </div>

            </form>
            </div>


            {/*modal*/}
            <div className="modal fade" id="staticBackdrop6" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pb-5">
                            This can later be adjusted for each vehicle on the vehicle dashboard and it will serve as a payment plan for that vehicle
                        </div>
                        <div className="modal-footer border-0">
                            <button ref={ref} type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop6" >Close</button>

                            {/*<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*/}
                            {/*<button type="button" className="btn btn-primary">Save changes</button>*/}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        handleUpdate: (data,partner_driver_payment, imgUrl, cacDoc) => dispatch(updateProfile(data, partner_driver_payment, imgUrl, cacDoc)),
        retrieveAvailableVehicle: () => dispatch(retrieveAvailableVehicle()),

    };
}

const mapStateToProps = (state) => ({
    signup_info: state.auth.signup_info,
    loading: state.loading.loading,
    availableVehiclesCount: state.vehicles.availableVehiclesCount
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfile);

