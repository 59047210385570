import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

const PaymentTab = ({data, loading, updateDriverPayoutMethod}) => {
    const [partner_driver_payment, setPartner_driver_payment] = useState({payment_type:"percent", driver_payment: "" //number
    })
    return (
        <div className='col-sm-6'>
            <form onSubmit={(e) => {
                e.preventDefault();
                updateDriverPayoutMethod(partner_driver_payment)
                console.log(partner_driver_payment, 'wwwww')
            }}>

                {/*<div className='mt-3 fw-bold' style={{fontSize: '20px'}}>Driver's Payment</div>*/}
                <div className="form-group">
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Payment type </label>
                    <select  className='form-select' value={partner_driver_payment.payment_type} onChange={(e) => setPartner_driver_payment({...partner_driver_payment, payment_type: e.target.value})}  required>
                        <option value="percent">Percent</option>
                        <option value="fixed">Salary</option>
                    </select>
                </div>
                <div className="form-group w-100 mr-1">
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>{partner_driver_payment.payment_type === 'percent' ? 'Percent to be paid' : 'Amount to be paid'}</label>
                    <input type="number" className="form-control"  placeholder="Amount to be paid to driver" value={partner_driver_payment.driver_payment}  onChange={(e) => setPartner_driver_payment({...partner_driver_payment, driver_payment: e.target.value})}  required/>
                </div>
                <button disabled={loading} type="submit" className="btn btn-primary w-100 mt-5 appBg-button">Submit</button>

            </form>

        </div>
    )
}

export default PaymentTab
