import React, { useEffect, useState } from 'react'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { getRevenueDaily, getRevenueDailyCount } from "../../actions/earningsAction";
import Pagination from "react-js-pagination";
import {
   calculatePostDate,
   formatMoney,
   getFirstDayOfMonth,
   getLastDayOfMonth,
   getTodayDate
} from "../../helpers/helpers";

const Payments = ({ signup_info, getRevenueDaily, revenueDaily, loading, revenueDailyCount, getRevenueDailyCount }) => {
   const [currentPage, setCurrentPage] = useState(1);
   const [type, setType] = useState('today')

   useEffect(() => {
      apply()
   }, [])

   const paginate = (pageNumber) => {
      getRevenueDaily(pageNumber)
      setCurrentPage(pageNumber);
   };

   const apply = () => {
      if (type === 'today') {
         getRevenueDaily(1)
         getRevenueDailyCount()
      }
      if (type === 'month') {
         getRevenueDaily(1, getFirstDayOfMonth(), getLastDayOfMonth())
         getRevenueDailyCount(getFirstDayOfMonth(), getLastDayOfMonth())

      }
      if (type === 'all') {
         getRevenueDaily(1)
         getRevenueDailyCount()

      }
   }

   return (
      <div className="table-responsive" style={{ minHeight: "50vh" }}>
         <div style={{ color: '#474242', fontSize: '30px', fontWeight: '600' }} className='mb-3'>Earnings</div>
         <div style={{ display: "flex", marginBottom: '10px' }}>
            <div className="form-group">
               <select className='form-select selectInput' value={type} name='gender' onChange={(e) => setType(e.target.value)}>
                  <option value='today'>Daily</option>
                  <option value="month">This Month</option>
                  <option value="all">All Time</option>
               </select>
            </div>
            <button onClick={apply} disabled={loading} type="button" className="btn btn-primary appBg-button">Apply</button>

         </div>
         <>
            <Table>
               <TableHead>
                  <TableRow style={{ background: '#DCFFFD' }} hover>
                     <TableCell>Date</TableCell>
                     <TableCell>No. of Trips</TableCell>
                     <TableCell>Vehicle Plate No</TableCell>
                     <TableCell>Gross Earnings</TableCell>
                     <TableCell>LASG Tax</TableCell>
                     <TableCell>Service Charge</TableCell>
                     <TableCell>Debt Service</TableCell>
                     <TableCell>Net Earnings</TableCell>
                     <TableCell>Driver’s Earnings</TableCell>
                     <TableCell>Partner’s earning</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  <>
                     {revenueDaily.map((earning, key) => (
                        <TableRow hover key={key}>
                           <TableCell>{new Date(earning?.createdAt).toDateString()}</TableCell>
                           <TableCell>{earning?.split_stage?.total_trip}</TableCell>
                           <TableCell>{earning?.car_number_plate}</TableCell>
                           <TableCell>₦{formatMoney(earning?.split_stage?.total_amount_made)}</TableCell>
                           <TableCell>₦{formatMoney(earning?.split_stage?.daily_tax_charge)}</TableCell>
                           <TableCell>₦{formatMoney(earning?.split_stage?.service_charge)}</TableCell>
                           <TableCell>₦{formatMoney(earning?.split_stage?.debt_service_charge || earning.debt_service)}</TableCell>
                           <TableCell>₦{formatMoney(earning?.split_stage?.amount_after_debt_service || earning?.split_stage?.amount_after_service_charge)}</TableCell>
                           <TableCell>₦{formatMoney(earning?.earning)}</TableCell>
                           <TableCell>₦{formatMoney(earning.partner_earning)}</TableCell>
                        </TableRow>
                     ))}
                  </>
               </TableBody>
            </Table>
            {revenueDaily.length > 0 && <div className="d-flex justify-content-end align-items-center mb-0 mt-3 mr-2">
               <Pagination activeLinkClass='info' activePage={currentPage} itemClass="page-item" linkClass="page-link" itemsCountPerPage={20} totalItemsCount={revenueDailyCount} onChange={paginate} />
            </div>}
            {!loading && revenueDaily.length === 0 &&
               <div className='text-center mt-5'>No earnings available</div>
            }
         </>
      </div>
   );
}

function mapDispatchToProps(dispatch) {
   return {
      getRevenueDaily: (page, start_date, end_date) => dispatch(getRevenueDaily(page, start_date, end_date)),
      getRevenueDailyCount: (start_date, end_date) => dispatch(getRevenueDailyCount(start_date, end_date)),
   };
}

const mapStateToProps = (state) => ({
   signup_info: state.auth.signup_info,
   loading: state.loading.loading,
   revenueDaily: state.earnings.revenueDaily,
   revenueDailyCount: state.earnings.revenueDailyCount
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
