import React, {useState} from 'react'
import SessionLayout from "../../layouts/SessionLayout";
import {connect} from "react-redux";
import {resendPin, verifyPin} from "../../actions/authAction";
import OtpForm from "../../components/otpForm/OtpForm";
import Timer from "../../components/timer/Timer";
import { useNavigate, useLocation } from "react-router-dom";




const VerifyOtp = ({handleVerifyPin, signup_info, loading, resendPin, forgot_password_email}) => {
    const navigate = useNavigate();
    const params = useLocation();
    const [isResend, setResend] = useState(true)

    return (
        <SessionLayout title=''>
            <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3 text-center'>{params?.state?.forgetPassword ? 'Enter pin to reset password' : 'Verify email'}</div>
            <div className='text-center'>
                Enter the 4 - digits code sent to {params?.state?.forgetPassword ? forgot_password_email : signup_info?.email}
            </div>
            <OtpForm loading={loading} onSubmit={(value) => handleVerifyPin(params?.state?.forgetPassword ? {email: forgot_password_email} : signup_info, value, navigate, params?.state?.forgetPassword ? 'forgot-password': null)} />
            <div className='mt-3 text-center'>
                Didn’t receive a code? <span style={{color: '#2096D9'}}> <button disabled={isResend} onClick={() => resendPin(params?.state?.forgetPassword ? {email: forgot_password_email} : signup_info, setResend)} style={{color: '#2096D9'}}  className="btn btn-link text-decoration-none">Resend in</button></span>
            </div>
            <Timer setResend={setResend} />
        </SessionLayout>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        handleVerifyPin: (data, pin, navigate, type) => dispatch(verifyPin(data, pin, navigate, type)),
        resendPin: (data, setResend) => dispatch(resendPin(data, setResend)),
    };
}

const mapStateToProps = (state) => ({
    signup_info: state.auth.signup_info,
    forgot_password_email: state.auth.forgot_password_email,
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);

