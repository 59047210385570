import {LOADING_END, LOADING_START} from "../actions/types";


const INIT_STATE = {
    loading: false,
    loadingStatus: false
};

const loadingReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOADING_START:
            return { ...state, loading: true};
        case LOADING_END:
            return { ...state, loading: false};
        default: return { ...state };
    }
}

export default loadingReducer
