import React from "react";
import "./topbar.css";
import { Settings, } from "@material-ui/icons";
import {logout, updateProfile} from "../../actions/authAction";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCar,
  faCog,
  faDashboard,
  faHandsHelping,
  faMoneyBill1, faSignOut,
  faUserCog, faBars,
  faCashRegister
} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";

const  Topbar = ({signup_info, handleLogout}) => {
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topLeft">
          <span className="logo">
                      <img src={require('../../assets/images/Logo.png')} alt="lagosride logo" style={{width: '150px'}}  />
          </span>
        </div>
        <div className="topRight">
          {/*<div className="topbarIconContainer">*/}
          {/*  <NotificationsNone />*/}
          {/*  <span className="topIconBadge">2</span>*/}
          {/*</div>*/}
          {/*<div className="topbarIconContainer">*/}
          {/*  <Language />*/}
          {/*  <span className="topIconBadge">2</span>*/}
          {/*</div>*/}
          <div className="topbarIconContainer">
            <NavLink to="/settings">
              <Settings style={{color: 'grey'}} />
            </NavLink>
          </div>
          <NavLink className='app-mobile-hide' to="/profile">
          <img src={signup_info?.avatar ? signup_info?.avatar : require('../../assets/images/avatar.png')} alt="" className="topAvatar" />
          </NavLink>
        </div>
        <button style={{background: 'linear-gradient(105.78deg, #2FB5D9 30.68%, #6FDB7A 130.02%)', fontSize: '15px'}} className="navbar-toggler btn btn-app app-mobile-show" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon">
                              <FontAwesomeIcon className="sidebarIcon" icon={faBars} />

          </span>
        </button>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light navy" style={{marginBottom: '50px'}}>
        <div className="container-fluid">
          {/*<a className="navbar-brand" href="#">Navbar</a>*/}
          {/*<button style={{background: 'linear-gradient(105.78deg, #2FB5D9 30.68%, #6FDB7A 130.02%)', fontSize: '15px'}} className="navbar-toggler btn btn-app" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"*/}
          {/*        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">*/}
          {/*  <span className="navbar-toggler-icon"></span>*/}
          {/*</button>*/}
          <div className="collapse navbar-collapse" id="navbarNav" style={{background: '#0B6699', position: 'relative'}}>
            <div style={{height: '100vh', width: '100%', position: 'absolute', top: 0, zIndex: -1}} data-bs-toggle="collapse" data-bs-target=".navbar-collapse"></div>
            <ul>
              <NavLink to="/" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                <FontAwesomeIcon className="sidebarIcon" icon={faDashboard} />
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Dashboard</span>
              </NavLink>
              {/*<NavLink to="/drivers" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>*/}
              {/*  <FontAwesomeIcon className="sidebarIcon" icon={faUser} />*/}
              {/*  <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Drivers</span>*/}
              {/*</NavLink>*/}
              <NavLink to="/vehicles" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                <FontAwesomeIcon className="sidebarIcon" icon={faCar} />
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Vehicles</span>
              </NavLink>

              <NavLink to="/payments" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                <FontAwesomeIcon className="sidebarIcon" icon={faMoneyBill1} />
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Earnings</span>
              </NavLink>
              <NavLink to="/cashout" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                <FontAwesomeIcon className="sidebarIcon" icon={faCashRegister} />
                Cash Out
              </NavLink>
              <NavLink to="/support" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                <FontAwesomeIcon className="sidebarIcon" icon={faHandsHelping} />
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Support</span>
              </NavLink>
              <NavLink to="/profile" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                <FontAwesomeIcon className="sidebarIcon" icon={faUserCog} />
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Profile</span>
              </NavLink>
              <NavLink to="/settings" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                <FontAwesomeIcon className="sidebarIcon" icon={faCog} />
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Settings</span>
              </NavLink>
              <li onClick={() => handleLogout()}  className='link sidebarListItem'>
                <FontAwesomeIcon className="sidebarIcon" icon={faSignOut} />
                <span data-bs-toggle="collapse" data-bs-target=".navbar-collapse">Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    handleUpdate: (data, imgUrl) => dispatch(updateProfile(data, imgUrl)),
    handleLogout: () => dispatch(logout()),
  };
}

const mapStateToProps = (state) => ({
  signup_info: state.auth.signup_info,
});

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
