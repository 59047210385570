import React, {useState} from "react";
import { Link } from "react-router-dom";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import SessionLayout from "../../layouts/SessionLayout";
import {loginUser} from "../../actions/authAction";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Login = ({handleLogin, loading}) => {

    const [formData, setFormData] = useState({email: '', password: ''})
    const [peek, setPeek] = useState(false);

    return (
        <SessionLayout title='Partner Sign in'>
            <form onSubmit={(e) => {
                e.preventDefault();
                handleLogin(formData)
            }}>
                <div className="form-group w-100 mr-1">
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Email</label>
                    <input type="email" className="form-control" defaultValue={formData.email}  placeholder="Email" onChange={(e) => setFormData({...formData, email: e.target.value})} required />
                </div>
                <div className="form-group w-100 mr-1" style={{position: 'relative'}}>
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Password</label>
                    <input type={peek ? 'text' : 'password'} className="form-control" defaultValue={formData.password}  placeholder="Password" onChange={(e) => setFormData({...formData, password: e.target.value})} required  />
                    <FontAwesomeIcon onClick={() => setPeek(!peek)} icon={peek ? faEyeSlash : faEye} style={{fontSize: '15px', position: 'absolute', right: '15px', bottom: '10px', cursor: 'pointer'}} />

                </div>
                <div className='mt-1 justify-content-end d-flex'>
                    <span style={{color: '#2096D9'}}> <Link to="/forgot-password" className="link">Forgot password?</Link></span>
                </div>
                <button disabled={loading} type="submit" className="btn btn-primary w-100 mt-5 appBg-button">Submit</button>
            </form>
            <div className='mt-3'>
                Do not have an account?  <span style={{color: '#2096D9'}}>
                <a onClick={() => alert('Sorry, we are over-subscribed and putting registration on hold till further notice. Thank you for choosing us')} className="link" role="button">Sign up</a>
                {/*<Link on to="/register" className="link">Sign up</Link>*/}
            </span>
            </div>
        </SessionLayout>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        handleLogin: (data) => dispatch(loginUser(data)),
    };
}

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
