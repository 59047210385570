/**
 * App Reducers
 */
import {combineReducers} from "redux";
import loadingReducer from "./LoadingReducer";
import authReducer from "./authReducer";
import driversReducer from "./driversReducer";
import vehiclesReducer from "./vehiclesReducer";
import earningsReducer from "./earningsReducer";

const reducers = combineReducers({
  loading: loadingReducer,
  auth: authReducer,
  drivers: driversReducer,
  vehicles: vehiclesReducer,
  earnings: earningsReducer,
});

export default reducers;
