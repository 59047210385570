import "./sidebar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDashboard, faUser, faCar, faMoneyBill1, faHandsHelping, faUserCog , faSignOut, faCog, faCashRegister} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom";
import {logout} from "../../actions/authAction";
import {connect} from "react-redux";

const Sidebar = ({handleLogout}) => {
  return (
      <>

        <div className="sidebar">
          <div className="sidebarWrapper">
            <div className="sidebarMenu">
              {/*<h3 className="sidebarTitle">Dashboard</h3>*/}
              <ul className="sidebarList">
                <NavLink to="/" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                  <FontAwesomeIcon className="sidebarIcon" icon={faDashboard} />
                  Dashboard
                </NavLink>
                {/*<NavLink to="/drivers" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>*/}
                {/*  <FontAwesomeIcon className="sidebarIcon" icon={faUser} />*/}
                {/*  Drivers*/}
                {/*</NavLink>*/}
                <NavLink to="/vehicles" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                  <FontAwesomeIcon className="sidebarIcon" icon={faCar} />
                  Vehicles
                </NavLink>

                <NavLink to="/payments" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                  <FontAwesomeIcon className="sidebarIcon" icon={faMoneyBill1} />
                  Earnings
                </NavLink>
                <NavLink to="/cashout" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                  <FontAwesomeIcon className="sidebarIcon" icon={faCashRegister} />
                  Cash Out
                </NavLink>
                <NavLink to="/support" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                  <FontAwesomeIcon className="sidebarIcon" icon={faHandsHelping} />
                  Support
                </NavLink>
                <NavLink to="/profile" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                  <FontAwesomeIcon className="sidebarIcon" icon={faUserCog} />
                  Profile
                </NavLink>
                <NavLink to="/settings" className={({ isActive }) => isActive ? 'active link sidebarListItem' : 'link sidebarListItem'}>
                  <FontAwesomeIcon className="sidebarIcon" icon={faCog} />
                  Settings
                </NavLink>
                <li onClick={() => handleLogout()} style={{position: 'absolute', bottom: '10%'}} className='link sidebarListItem'>
                  <FontAwesomeIcon className="sidebarIcon" icon={faSignOut} />
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    handleLogout: () => dispatch(logout()),
  };
}

const mapStateToProps = (state) => ({
  signup_info: state.auth.signup_info,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
