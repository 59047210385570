import axios from "axios";
import {NotificationManager} from "react-notifications";
import AppEndpoint from "../assets/utils/AppEndpoint";
import {AUTH, SETTING, SIGNUP_INFO, FORGOT_PASSWORD_EMAIL, LOG_OUT, EXIST} from "./types";
import {endLoading, startLoading} from "./loadingAction";
import {configureStore} from "../store";
import {onClickReset} from "../components/timer/Timer";
import appEndpoint from "../assets/utils/AppEndpoint";



export const signupAction = (data, navigate, handleExistUserModal) => async (dispatch) => {
  try {

    if (data?.password.length < 8) {
      return NotificationManager.error('Password must be 8 or more characters');
    }
    //check if there's capital letter
    if (!/[A-Z]/.test(data?.password)) {
      return NotificationManager.error('Password must have at least a capital letter');
    }
    if (!/[a-z]/.test(data?.password)) {
      NotificationManager.error('Password must have at least a small letter');
    }
    if (!/[0-9]/.test(data?.password)) {
     return  NotificationManager.error('Password must have at least a number');
    }

    if (data?.phone_number.length < 11) {
      return NotificationManager.error('Phone number not complete');
    }
    //if the value is
    if (!/^0/.test(data?.phone_number)) {
      return NotificationManager.error('Phone number must start with zero. e.eg 090.....');
    }
    //if the value is
    if (data?.phone_number.length > 11) {
      return NotificationManager.error('Phone number too long');
    }
    if (data.first_name.length < 2) {
      return NotificationManager.error(data?.account_type === 'organization' ? "Organisation name is too short" : "First name is too short")
    }
    if (data.first_name.length > 25) {
      return NotificationManager.error(data?.account_type === 'organization' ? "Organisation name is too long" :  "First name is too long")
    }
    if (!/^[a-z\-\s]+$/i.test(data.first_name)) {
      return NotificationManager.error(data?.account_type === 'organization' ? "Organisation name should be alphabets only" : "First name should be alphabets only")
    }

    if (data.last_name.length < 2) {
      return NotificationManager.error("Last name is too short")
    }
    if (data.last_name.length > 25) {
      return NotificationManager.error( "Last name is too long")
    }
    if (!/^[a-z\-\s]+$/i.test(data.last_name)) {
      return NotificationManager.error("Last name should be alphabets only")
    }

    // if (data?.nin_id.length < 11) {
    //   return NotificationManager.error('NIN not complete');
    // }


    if(data?.account_type === 'organization' && /lrtester/i.test(data?.first_name)) {
      const testerName = data?.first_name.split(' ')
      const fn = testerName.splice(0, testerName.length - 1)
      data.first_name = fn.join(' ')
      data.last_name = testerName[testerName.length - 1]
    }
    dispatch(startLoading())
    AppEndpoint.configureEndpoint(data?.last_name?.trim())
    const userExist = await axios.post(`${appEndpoint.UserService}/auth/validate-registration`, data)
    console.log(userExist.data, 'pppppppppppp')

    // const userExist = await axios.get(`${AppEndpoint.VerifyUser}?phone_number=${data?.phone_number}&email=${data?.email}&user_type=partner`)

    if (userExist.data.status === 'ok') {
      dispatch({
        type: EXIST,
        payload: userExist.data,
      });
      if (userExist?.data?.exist === true && userExist?.data?.can_register === true) {
        if(typeof handleExistUserModal === "function") {
          dispatch(endLoading())
        return   handleExistUserModal()
        }
      }
      //if any of the account exist
      if (userExist?.data?.exist === true && userExist?.data?.can_register === false) {
        //if email exist
        if (userExist.data.email === true) {
          NotificationManager.error('Email already in use')
        }
        //if phone exsit
        if (userExist.data.phone_number === true) {
          NotificationManager.error('Phone number already in use')
        }
        dispatch(endLoading())
        return
      }
      const generatePin = await axios.post(`${AppEndpoint.GeneratePin}`, { email: data?.email, type: 'otp' })
      if (generatePin.data.status === 'ok') {
        dispatch({
          type: SIGNUP_INFO,
          payload: data,
        });
        NotificationManager.success('Otp has been sent to your email')
        dispatch(endLoading())
        return navigate("/verify-otp")
      }
      dispatch(endLoading())
    } else {
      dispatch(endLoading())
      return NotificationManager.error(userExist.data.msg)
    }
  } catch (e) {
    dispatch(endLoading())
  }
};


export const verifyPin = (data, pin, navigate, type) => async (dispatch) => {
  dispatch(startLoading())
  try {
    const res = await axios.post(`${AppEndpoint.ConfirmPin}`, {email: data?.email, pin})
    if(res.data.status === 'ok') {
      dispatch(endLoading())
      if(type === 'forgot-password') {
        return navigate("/create-password",{
            state:{
          email: data?.email,
          pin: pin,
        },
      })
      } else {
        dispatch(createUser({...data, verification_pin: pin}, navigate))
      }
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const createUser = (data, navigate) => async (dispatch) => {
  dispatch(startLoading())
  try {
    //set the environment for API
    AppEndpoint.configureEndpoint(data?.last_name?.trim())

    const res = await axios.post(AppEndpoint.RegisterUser, data)
    if(res.data.status === 'ok') {
    await  localStorage.setItem('ud_partner', JSON.stringify(res.data.data))
     await localStorage.setItem('token', res.data.data.token)
      dispatch(endLoading())
    await  dispatch({
        type: SIGNUP_INFO,
        payload: res.data.data,
      });
    await  dispatch({
        type: AUTH,
        payload: res.data.data.token,
      });
    await dispatch(retrieveUserDetails(res.data.data.auth_id))
      // eslint-disable-next-line no-restricted-globals
     return   location.replace("/profile");
    } else {
      NotificationManager.error(res?.data?.msg)
    }
    dispatch(endLoading())

  }catch (e) {
    dispatch(endLoading())

  }
}


export const createExistingUser = (data, existData, handleExistUserModal) => async (dispatch) => {
  dispatch(startLoading())
  try {
    //set the environment for API
    AppEndpoint.configureEndpoint(data?.last_name?.trim())

    const res = await axios.post(AppEndpoint.PostRegisterUser, existData)
    if(res.data.status === 'ok') {
      await  localStorage.setItem('ud_partner', JSON.stringify(res.data.data))
      await localStorage.setItem('token', res.data.data.token)
      dispatch(endLoading())
      await  dispatch({
        type: SIGNUP_INFO,
        payload: res.data.data,
      });
      await  dispatch({
        type: AUTH,
        payload: res.data.data.token,
      });
      if(typeof handleExistUserModal === "function") {
        handleExistUserModal()
      }
      await dispatch(retrieveUserDetails(res.data.data.auth_id, res.data.data.token))
       // eslint-disable-next-line no-restricted-globals
      return   location.replace("/profile");
    } else {
      NotificationManager.error(res?.data?.msg)
    }
    dispatch(endLoading())

  }catch (e) {
    dispatch(endLoading())

  }
}


export const retrieveUserDetails = (auth_id, token) => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  const headers = {
    headers: {
      'Authorization': token ? token : userInfo?.token
    }
  }
  dispatch(startLoading())
  try {

    const res = await axios.get(AppEndpoint.CreateUser + auth_id, headers)
    if(res.data.status === 'ok') {
      await  localStorage.setItem('ud_partner', JSON.stringify(res.data.data))
      dispatch(endLoading())
      dispatch({
        type: SIGNUP_INFO,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res?.data?.msg)
    }
    dispatch(endLoading())

  }catch (e) {
    dispatch(endLoading())

  }
}

export const refreshUserDetails = (auth_id, showPaymentModal) => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {

    const res = await axios.get(AppEndpoint.CreateUser + auth_id)
    if(res.data.status === 'ok') {
      if(res.data.data.partner_data?.partner_status === 0) {
        NotificationManager.success('Account still under verification')
      }
        if(res.data?.data?.partner_data?.partner_status === 4) {
          // const fv = res?.data?.data?.partner_data?.vehicle_interested?.filter(x => x.status === 0)
          // eslint-disable-next-line no-restricted-globals
          return   location.replace(  "/");
        } else {
          // eslint-disable-next-line no-restricted-globals
          location.replace( "/vehicles");
        }
      // if(res.data.data.partner_data?.partner_status === 2) {
      //   // eslint-disable-next-line no-restricted-globals
      //   return   location.replace( "/vehicles");
      //   // showPaymentModal()
      // }

      await  localStorage.setItem('ud_partner', JSON.stringify(res.data.data))
      dispatch(endLoading())
      dispatch({
        type: SIGNUP_INFO,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res?.data?.msg)
    }
    dispatch(endLoading())

  }catch (e) {
    dispatch(endLoading())

  }
}


export const updateProfile = (data, partner_driver_payment = {}, imgUrl, cacDoc, handleModalHideVehicle) => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  // data = partner_driver_payment?.driver_payment ? {...data, partner_driver_payment} : data

  if(userInfo.partner_data.account_type === 'organization' && /lrtester/i.test(data?.first_name)) {
    const testerName = data?.first_name.split(' ')
    const fn = testerName.splice(0, testerName.length - 1)
    data.first_name = fn.join(' ')
    data.last_name = testerName[testerName.length - 1]
  }
  dispatch(startLoading())
  try {
    //upload profile image or logo
    if(imgUrl?.name) {
      const formData = new FormData();
      formData.append('photo', imgUrl);
    const profileUpload = await  axios.post(AppEndpoint.UploadProfileImage, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if(profileUpload.data.status === 'ok') {
        // NotificationManager.success('profile upload success')
      } else {
        NotificationManager.error(profileUpload.data.msg)
      }

    }

    //upload cac
    if(cacDoc?.name) {
      const formData = new FormData();
      formData.append('doc', cacDoc);
      const cacUpload = await  axios.post(AppEndpoint.UploadCAC, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      if(cacUpload.data.status === 'ok') {
        // NotificationManager.success('profile upload success')
      } else {
        NotificationManager.error(cacUpload.data.msg)
      }

    }


    const res = await axios.put(AppEndpoint.CreateUser + userInfo?.auth_id, data)
    dispatch(endLoading())
    if(res.data.status === 'ok') {
      await  localStorage.setItem('ud_partner', JSON.stringify(res.data.data))
      dispatch(endLoading())
      dispatch({
        type: SIGNUP_INFO,
        payload: res.data.data,
      });
     NotificationManager.success('Updated successfully')
      if(typeof handleModalHideVehicle === "function") {
        handleModalHideVehicle()
      }else {
        setTimeout(() => {
          // eslint-disable-next-line no-restricted-globals
          return  location.replace("/");
        },200)
      }
    } else {
      NotificationManager.error(res?.data?.msg)
    }
  }catch (e) {
    dispatch(endLoading())
  }
}



export const loginUser = (data) => async (dispatch) => {
  const body = {...data, user_type: "partner"};
  try {
    dispatch(startLoading());
    const res = await axios.post(AppEndpoint.Login2, body);
   if (res.data.status === 'ok') {
     //set the environment for API
     AppEndpoint.configureEndpoint(res.data.data?.last_name?.trim())
     const userAccount = res.data.data.user_account;
     if (userAccount.includes('partner')) {
       await  localStorage.setItem('ud_partner', JSON.stringify(res.data.data))
       await localStorage.setItem('token', res.data.data.token)
       dispatch({
         type: SIGNUP_INFO,
         payload: res.data.data,
       });
       dispatch(endLoading())

       // eslint-disable-next-line no-restricted-globals
       // if(res.data?.data?.partner_data?.partner_status === 4) {
       //   const fv = res?.data?.data?.partner_data?.vehicle_interested?.filter(x => x.status === 0)
       //
       //
       //   // eslint-disable-next-line no-restricted-globals
       //   return   location.replace( fv > -1 ? "vehicles" : "/");
       // } else {
         // eslint-disable-next-line no-restricted-globals
         return   location.replace("/");
       // }

     } else {
       NotificationManager.error("Invalid phone or password");
     }
   } else {
     NotificationManager.error(res.data.msg);
   }
    dispatch(endLoading());
  } catch (err) {
    dispatch(endLoading());
    NotificationManager.error("Network error please try again or check your internet connection ");
  }
};


export const logout = () => async (dispatch) => {
  try {
    await localStorage.clear()
    dispatch({
      type: LOG_OUT,
    });
    // eslint-disable-next-line no-restricted-globals
    return   location.replace("/login");
  }catch (e) {

  }
}


export const generatePin = (data, openModal) => async (dispatch) => {

  if (data?.password.length < 8) {
    return NotificationManager.error('Password must be 8 or more characters');
  }
  //check if there's capital letter
  if (!/[A-Z]/.test(data?.password)) {
    return NotificationManager.error('Password must have at least a capital letter');
  }
  if (!/[a-z]/.test(data?.password)) {
    NotificationManager.error('Password must have at least a small letter');
  }
  if (!/[0-9]/.test(data?.password)) {
    return  NotificationManager.error('Password must have at least a number');
  }
  try {
    dispatch(startLoading())
    const generatePin = await axios.post(`${AppEndpoint.GeneratePin}`, { email: data?.email, type: 'otp' })
    if (generatePin.data.status === 'ok') {
      NotificationManager.success('Otp has been sent to your email')
      if(typeof openModal === "function") {
        openModal()
      }
    } else {
      NotificationManager.error(generatePin?.data?.msg)
    }
    dispatch(endLoading())

  }catch (e) {
    dispatch(endLoading())
  }
}

export const changePassword = (data, pin) => async (dispatch) => {
  dispatch(startLoading())
  try {
    const res = await axios.post(`${AppEndpoint.ConfirmPin}`, {email: data?.email, pin})
    if(res.data.status === 'ok') {
      const passwordReset = await axios.put(`${AppEndpoint.ResetPassword}`, data)
      if(passwordReset.data.status === 'ok') {
        NotificationManager.success('Password changed successfully')
        setTimeout(() => {
          dispatch(logout())
        },500)
      } else {
        NotificationManager.error(passwordReset.data.msg)
      }
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const resendPin = (data, setResend) => async (dispatch) => {
  dispatch(startLoading())
  try {
    const generatePin = await axios.post(`${AppEndpoint.GeneratePin}`, { email: data?.email, type: 'otp' })
    if (generatePin.data.status === 'ok') {
      if(typeof setResend === "function") {
        setResend(true)
      }
      onClickReset()
      NotificationManager.success('Otp has been sent to your email')
    } else {
    NotificationManager.error(generatePin.data.msg)
  }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const getSetting = () => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  try {
    const res = await axios.get(`${AppEndpoint.Setting}/v1.1/settings?fields=com_driver_fee`)
    if (res.data.status === 'ok') {
      dispatch({
        type: SETTING,
        payload: res.data.data,
      });
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const cancelVehicleInterest = (handleHideModal, vehicle_interested_id) => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  try {
    const res = await axios.delete(`${AppEndpoint.UserService}/users/partner-vehicle-interested/${vehicle_interested_id}`)
    if (res.data.status === 'ok') {
      NotificationManager.success('Interest cancelled successfully')
      if(typeof handleHideModal === 'function') {
        handleHideModal()
      }
      dispatch(getUserProfile())
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}

export const getUserProfile = () => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
  try {
    const user = await axios.get(`${AppEndpoint.UserService}/users/${userInfo.auth_id}`)
    if (user.data.status === 'ok') {
      await  localStorage.setItem('ud_partner', JSON.stringify(user.data.data))
      dispatch({
        type: SIGNUP_INFO,
        payload: user.data.data,
      });
    } else {
      NotificationManager.error(user.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}


export const verifyUser = (data, navigate) => async (dispatch) => {
  dispatch(startLoading())
  try {
    const res = await axios.get(`${AppEndpoint.VerifyUser}?email=${data.email}`)
    if (res.data.status === 'ok') {
      if(res.data.email === true) {
        dispatch({
          type: FORGOT_PASSWORD_EMAIL,
          payload: data.email,
        });
        await dispatch(resendPin(data))
        dispatch(endLoading())
        return navigate("/verify-otp", {
          state:{
            forgetPassword: true,
          },
        })
      } else {
        NotificationManager.error('Email not found')
      }
    } else {
      NotificationManager.error(res.data.msg)
    }
    dispatch(endLoading())
  }catch (e) {
    dispatch(endLoading())
  }
}




export const addVehicleInterested = (data, handleHideModal) => async (dispatch) => {
  const userInfo = configureStore.getState().auth.signup_info
  AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())

  dispatch(startLoading())
  try {
    const res = await axios.put(AppEndpoint.CreateUser + userInfo?.auth_id, data)
    dispatch(endLoading())
    if(res.data.status === 'ok') {
      await  localStorage.setItem('ud_partner', JSON.stringify(res.data.data))
      dispatch(endLoading())
      dispatch({
        type: SIGNUP_INFO,
        payload: res.data.data,
      });
      await dispatch(retrieveUserDetails(userInfo?.auth_id))
      NotificationManager.success('Successfully')
      if(typeof handleHideModal === "function") {
        handleHideModal()
      }
    } else {
      NotificationManager.error(res?.data?.msg)
    }
  }catch (e) {
    dispatch(endLoading())
  }
}








