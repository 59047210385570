import React, {useState, useRef, useEffect} from 'react'
import {Link, useNavigate} from "react-router-dom";
import SessionLayout from "../../layouts/SessionLayout";
import {connect} from "react-redux";
import {createExistingUser, signupAction} from "../../actions/authAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {NotificationManager} from "react-notifications";


const Signup = ({handleSignup, loading, user_exist, createExistingUser}) => {
    const navigate = useNavigate();
    const ref = useRef(null)
    const [peek, setPeek] = useState(false);
    const [peek3, setPeek3] = useState(false);
    const [org_name, setOrg_name] = useState('')
    const [formData, setFormData] = useState({
        last_name: '',
        first_name: '',
        phone_number: '',
        email: '',
        password: '',
        account_type: 'individual',
        user_type: 'partner',
        account_class: 'B',
    })
    const [existUserData, setExistUserData] = useState({
        user_type: 'partner',
        account_class: 'B',
        password: '',
        account_type: formData.account_type
    })
    const [isChecked, setIsChecked] = useState(false);

    const handleOnChange = () => {
        setIsChecked(!isChecked);
    };

    const {last_name, first_name, account_type, email, password, phone_number, user_type, account_class} = formData

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const data =
        {
            first_name: account_type === 'individual' ? first_name : org_name,
            last_name: account_type === 'individual' ? last_name : org_name,
            account_type,
            email,
            password,
            phone_number,
            user_type,
            account_class,
        }

        console.log(user_exist)

    const handleModalOpen = () => {
        ref.current.click()
    }


    // can_register: true
    // email: true
    // exist: true
    // phone_number: true



    return (
        <SessionLayout title='Partner Sign up'>
            <form onSubmit={(e) => {
                e.preventDefault();
                if(!isChecked) {
                    return NotificationManager.error('Accept terms and conditions to proceed')
                }
                return alert('Sorry, we are over-subscribed and putting registration on hold till further notice. Thank you for choosing us')

                // handleSignup(data, navigate, handleModalOpen)
            }}>
                <div className="form-group">
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Register as an</label>
                    <select  className='form-select' value={account_type} onChange={onChange} name='account_type' required>
                        <option value="individual">Individual</option>
                        <option value="organization">Organization</option>
                    </select>
                </div>
                {/*<div className="form-group">*/}
                {/*    <label style={{color: '#747474'}} className='mt-3 mb-1'>Account class     <FontAwesomeIcon onClick={() => handleModalOpen()} role='button' className="sidebarIcon" icon={faInfoCircle} style={{color: 'red'}} /></label>*/}
                {/*    <select  className='form-select' value={account_class} onChange={onChange} name='account_class' required>*/}
                {/*        <option value="">Select Account class</option>*/}
                {/*        <option value="A">A</option>*/}
                {/*        <option value="B">B</option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                {account_type === 'organization' &&
                    <div className="form-group w-100 mr-1">
                        <label style={{color: '#747474'}} className='mt-3 mb-1'>Organisation Name</label>
                        <input type="text" className="form-control"  placeholder="Organisation Name" value={org_name}   onChange={(e) => setOrg_name(e.target.value)} required />
                    </div>
                }
                {account_type === 'individual' &&
                    <div className='d-flex justify-content-between'>
                        <div className="form-group w-100 mr-1">
                            <label style={{color: '#747474'}} className='mt-3 mb-1'>First Name</label>
                            <input type="text" className="form-control"  placeholder="First Name" value={first_name}  name='first_name' onChange={onChange} required />
                        </div>
                        <div style={{width: '20px'}} />
                        <div className="form-group w-100 ml-1">
                            <label style={{color: '#747474'}} className='mt-3 mb-1'>Last Name</label>
                            <input type="text" className="form-control"  placeholder="Last Name" value={last_name}  name='last_name' onChange={onChange} required />
                        </div>
                    </div>
                }
                <div className="form-group w-100 mr-1">
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Email</label>
                    <input type="email" className="form-control"  placeholder="Email" value={email}  name='email' onChange={onChange} required />
                </div>
                <div className="form-group w-100 mr-1">
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Phone Number</label>
                    <input type="number" maxLength={11} className="form-control"  placeholder="Phone Number" value={phone_number}  name='phone_number' onChange={onChange} required />
                </div>
                {/*<div className="form-group w-100 mr-1">*/}
                {/*    <label style={{color: '#747474'}} className='mt-3 mb-1'>Number of Vehicles interested</label>*/}
                {/*    <input type="number" className="form-control"  placeholder="Enter number of vehicles" value={vehicle_interested}  name='vehicle_interested' onChange={onChange} required />*/}
                {/*</div>*/}
                <div className="form-group w-100 mr-1" style={{position: 'relative'}}>
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Password</label>
                    <input type={peek ? 'text' : 'password'} className="form-control"  placeholder="Password" value={password}  name='password' onChange={onChange} required />
                    <FontAwesomeIcon onClick={() => setPeek(!peek)} icon={peek ? faEyeSlash : faEye} style={{fontSize: '15px', position: 'absolute', right: '15px', bottom: '10px', cursor: 'pointer'}} />
                </div>
                <div className="form-check mt-3">
                    <input className="form-check-input" type="checkbox" checked={isChecked} onChange={handleOnChange} />
                        <a  href='https://lagosride.com/privacy-policy' target="_blank" className="form-check-label text-decoration-none" htmlFor="flexCheckDefault" rel="noreferrer">
                            Agree to terms and conditions
                        </a>
                </div>
                <button disabled={loading} type="submit" className="btn btn-primary w-100 mt-5 appBg-button">Submit</button>
            </form>
            <div className='mt-3'>
                Already have an account? <span style={{color: '#2096D9'}}> <Link to="/login" className="link">Sign in </Link></span>
            </div>

            <div className="modal fade" id="staticBackdrop63" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {user_exist.email === true && user_exist.phone_number === true &&
                                <div>Your  email <span style={{fontWeight: 'bold', color: 'black'}}>{email}</span> and phone number <span style={{fontWeight: 'bold', color: 'black'}}>{phone_number}</span>  exist as a user on LagosRide platform, do you wish to proceed to use it as a partner on the platform?</div>
                            }
                            {user_exist.email === true && user_exist.phone_number === false &&
                                <div>Your  email <span style={{fontWeight: 'bold', color: 'black'}}>{email}</span> exist as a user on LagosRide platform, do you wish to proceed to use it as a partner on the platform?</div>
                            }
                            {user_exist.email === false && user_exist.phone_number === true &&
                                <div>Your  phone number <span style={{fontWeight: 'bold', color: 'black'}}>{phone_number}</span>  exist as a user on LagosRide platform, do you wish to proceed to use it as a partner on the platform?</div>
                            }
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            const data = user_exist?.email === true ? {...existUserData, email} : {...existUserData, phone_number}
                            createExistingUser(formData, data, handleModalOpen)
                        }}>
                            <div className='p-3'>
                                <div className="form-group w-100 mr-1" style={{position: 'relative'}}>
                                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Enter password associated with the account to proceed</label>
                                    <input type={peek3 ? 'text' : 'password'} className="form-control"  placeholder="Password" value={existUserData.password}   onChange={(e) => setExistUserData({...existUserData, password: e.target.value})} required />
                                    <FontAwesomeIcon onClick={() => setPeek3(!peek3)} icon={peek ? faEyeSlash : faEye} style={{fontSize: '15px', position: 'absolute', right: '15px', bottom: '10px', cursor: 'pointer'}} />
                                </div>
                            </div>
                            <div className="modal-footer border-0">
                                <button  type="submit" className="btn btn-success" >Proceed</button>

                                <button ref={ref} type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop63" >Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </SessionLayout>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        handleSignup: (data, navigate, handleExistUserModal) => dispatch(signupAction(data, navigate, handleExistUserModal)),
        createExistingUser: (data, existData, handleExistUserModal) => dispatch(createExistingUser(data, existData, handleExistUserModal)),

    };
}

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
    user_exist: state.auth.user_exist,
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);

