import {
    CASHOUT, CASHOUT_COUNT,
    REVENUE_BALANCE,
    REVENUE_DAILY,
    REVENUE_DAILY_COUNT,
    REVENUE_MONTHLY,
    WALLET_BALANCE
} from "../actions/types";


const INIT_STATE = {
   walletBalance: {},
    revenueBalance: {},
    revenueDaily: [],
    revenueDailyCount: 0,
    revenueMonthly: [],
    cashout: [],
    cashoutCount: 0,
};

const earningsReducer = (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case WALLET_BALANCE:
            return { ...state,  walletBalance: payload};
        case REVENUE_BALANCE:
            return { ...state,  revenueBalance: payload};
        case REVENUE_DAILY:
            return { ...state,  revenueDaily: payload};
        case REVENUE_DAILY_COUNT:
            return { ...state,  revenueDailyCount: payload};
        case REVENUE_MONTHLY:
            return { ...state,  revenueMonthly: payload};
        case CASHOUT:
            return { ...state,  cashout: payload};
        case CASHOUT_COUNT:
            return { ...state,  cashoutCount: payload};
        default: return { ...state };
    }
}

export default earningsReducer
