import React, { useEffect, useState } from 'react'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { getCashOutDaily, getCashOutDailyCount } from "../../actions/earningsAction";
import Pagination from "react-js-pagination";
import {
   formatMoney,
   getCashoutStatus, getCashoutStatusColor,
   getFirstDayOfMonth,
   getLastDayOfMonth,
} from "../../helpers/helpers";

const CashOut = ({ signup_info, getCashOutDaily, cashout, loading, cashoutCount, getCashOutDailyCount }) => {
   const [currentPage, setCurrentPage] = useState(1);
   const [type, setType] = useState('today')

   useEffect(() => {
      apply()
   }, [])

   const paginate = (pageNumber) => {
      getCashOutDaily(pageNumber)
      setCurrentPage(pageNumber);
   };

   const apply = () => {
      if (type === 'today') {
         getCashOutDaily(1)
         getCashOutDailyCount()
      }
      if (type === 'month') {
         getCashOutDaily(1, getFirstDayOfMonth(), getLastDayOfMonth())
         getCashOutDailyCount(getFirstDayOfMonth(), getLastDayOfMonth())

      }
      if (type === 'all') {
         getCashOutDaily(1)
         getCashOutDailyCount()

      }
   }

   return (
      <div className="table-responsive" style={{ minHeight: "50vh" }}>
         <div style={{ color: '#474242', fontSize: '30px', fontWeight: '600' }} className='mb-3'>Cash Out</div>
         <div style={{ display: "flex", marginBottom: '10px' }}>
            <div className="form-group">
               <select className='form-select selectInput' value={type} name='gender' onChange={(e) => setType(e.target.value)}>
                  <option value='today'>Daily</option>
                  <option value="month">This Month</option>
                  <option value="all">All Time</option>
               </select>
            </div>
            <button onClick={apply} disabled={loading} type="button" className="btn btn-primary appBg-button">Apply</button>

         </div>
         <>
            <Table>
               <TableHead>
                  <TableRow style={{ background: '#DCFFFD' }} hover>
                     <TableCell>Date</TableCell>
                     <TableCell>Name</TableCell>
                     <TableCell>Account Number</TableCell>
                     <TableCell>Bank</TableCell>
                     <TableCell>Amount Due</TableCell>
                     <TableCell>Wallet Deduction</TableCell>
                     <TableCell>Amount Paid</TableCell>
                     <TableCell>Status</TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  <>
                     {cashout.map((earning, key) => (
                        <TableRow hover key={key}>
                           <TableCell>{new Date(earning?.created).toDateString()}</TableCell>
                           <TableCell>{earning?.account_data?.account_name}</TableCell>
                           <TableCell>{earning?.account_data?.account_number}</TableCell>
                           <TableCell>{earning?.account_data?.bank_name}</TableCell>
                           <TableCell>₦{formatMoney(earning?.amount)}</TableCell>
                           <TableCell>₦{formatMoney(earning?.wallet_deduction || 0)}</TableCell>
                           <TableCell>₦{formatMoney(earning?.actual_amount)}</TableCell>
                           <TableCell> <div style={{ backgroundColor: getCashoutStatusColor(earning?.status), color: 'white', width: 'fit-content', borderRadius: '5px', fontWeight: 'bold' }} className='px-3'>{getCashoutStatus(earning?.status)}</div> </TableCell>
                        </TableRow>
                     ))}
                  </>
               </TableBody>
            </Table>
            {cashout.length > 0 && <div className="d-flex justify-content-end align-items-center mb-0 mt-3 mr-2">
               <Pagination activeLinkClass='info' activePage={currentPage} itemClass="page-item" linkClass="page-link" itemsCountPerPage={20} totalItemsCount={cashoutCount} onChange={paginate} />
            </div>}
            {!loading && cashout.length === 0 &&
               <div className='text-center mt-5'>No cash out available</div>
            }
         </>
      </div>
   );
}

function mapDispatchToProps(dispatch) {
   return {
      getCashOutDaily: (page, start_date, end_date) => dispatch(getCashOutDaily(page, start_date, end_date)),
      getCashOutDailyCount: (start_date, end_date) => dispatch(getCashOutDailyCount(start_date, end_date)),
   };
}

const mapStateToProps = (state) => ({
   signup_info: state.auth.signup_info,
   loading: state.loading.loading,
   cashout: state.earnings.cashout,
   cashoutCount: state.earnings.cashoutCount
});

export default connect(mapStateToProps, mapDispatchToProps)(CashOut);
