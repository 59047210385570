// import Config from "react-native-config";

const AppEndpoint = {};


//the API env
AppEndpoint.apiEnv = "live"

AppEndpoint.configureEndpoint = (env) => {
   // console.log("env", env)
   AppEndpoint.apiEnv = /^lrtester$/i.test(env) ? "test" : "live"

   //change the endpoint based on the env.
   AppEndpoint.AreaService = AppEndpoint.apiEnv === "live" ? 'https://area-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/area-service/v1.1';
   AppEndpoint.FileUploadService = AppEndpoint.apiEnv === "live" ? 'https://fileupload-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/fileupload-service/v1.1';
   // AppEndpoint.MessageService = AppEndpoint.apiEnv === "live" ? 'https://message-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/message-service/v1.1';
   AppEndpoint.MessageService = 'https://message-service-microservices.api.lagosride.com/v1.1';
   AppEndpoint.UserService = AppEndpoint.apiEnv === "live" ? 'https://users-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/user-service/v1.1';
   AppEndpoint.TripService = AppEndpoint.apiEnv === "live" ? 'https://trip-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/trip-service/v1.1';
   AppEndpoint.FDTService = AppEndpoint.apiEnv === "live" ? 'https://fdt-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/fdt-service/v1.1';
   AppEndpoint.TripReportService = AppEndpoint.apiEnv === "live" ? 'https://tripreporting-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/tripreporting-service/v1.1';
   AppEndpoint.RatingsService = AppEndpoint.apiEnv === "live" ? 'https://triprating-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/triprating-service/v1.1';
   AppEndpoint.TripCancelReasonsService = AppEndpoint.apiEnv === "live" ? 'https://tripcancel-reasons-microservices.api.lagosride.com' : 'https://staging-server.lagosride.com/trip-cancellation';
   AppEndpoint.SupportService = AppEndpoint.apiEnv === "live" ? 'https://support-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/support-service/v1.1';
   AppEndpoint.WalletService = AppEndpoint.apiEnv === "live" ? 'https://wallet-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/wallet-service/v1.1';
   AppEndpoint.SOSService = AppEndpoint.apiEnv === "live" ? 'https://sos-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/sos-service/v1.1';
   AppEndpoint.ReferralService = AppEndpoint.apiEnv === "live" ? 'https://referral-service-microservices.api.lagosride.com/v1.1' : 'https://staging-server.lagosride.com/referral-service/v1.1';
   AppEndpoint.SocketURL = AppEndpoint.apiEnv === "live" ? 'https://trip-service-microservices.api.lagosride.com' : 'https://staging-server.lagosride.com';
   AppEndpoint.VerifyUser = AppEndpoint.apiEnv === "live" ? `https://users-service-microservices.api.lagosride.com/v1.1/auth/verify-account/` : 'https://staging-server.lagosride.com/user-service/v1.1/auth/verify-account/';
   AppEndpoint.Setting = AppEndpoint.apiEnv === "live" ? ' https://settings-service-microservices.api.lagosride.com' : 'https://staging-server.lagosride.com/setting-service';
   AppEndpoint.VehicleService = AppEndpoint.apiEnv === "live" ? "https://vehicle-service-microservices.api.lagosride.com/v1.1" : "https://staging-server.lagosride.com/vehicle-service/v1.1";
   AppEndpoint.RevenueService = AppEndpoint.apiEnv === "live" ? "https://lrapi-nginx.lagosride.com/revenue-service/v1.1" : "https://staging-server.lagosride.com/revenue-service/v1.1";






   //message service
   AppEndpoint.GeneratePin = `${AppEndpoint.MessageService}/messages/send`
   AppEndpoint.ConfirmPin = `${AppEndpoint.MessageService}/messages/confirm-pin`
   //User service
   AppEndpoint.Logout = `${AppEndpoint.UserService}/auth/logout/`;
   AppEndpoint.COnfirmToken = `${AppEndpoint.UserService}/users/verify-token/`;
   AppEndpoint.CreateUser = `${AppEndpoint.UserService}/users/`;
   AppEndpoint.RegisterUser = `${AppEndpoint.UserService}/auth/register/`;
   AppEndpoint.PostRegisterUser = `${AppEndpoint.UserService}/auth/post-register/`;
   AppEndpoint.CheckUserName = `${AppEndpoint.UserService}/users/phone-number/`;
   AppEndpoint.Login2 = `https://users-service-microservices.api.lagosride.com/v1.1/auth/login/`;
   AppEndpoint.ResetPassword = `https://users-service-microservices.api.lagosride.com/v1.1/auth/reset-password/`;
   AppEndpoint.VerifyUser = `https://users-service-microservices.api.lagosride.com/v1.1/auth/verify-account/`;
   AppEndpoint.UploadProfileImage = `${AppEndpoint.FileUploadService}/files/profile-picture/`;
   AppEndpoint.UploadCAC = `${AppEndpoint.FileUploadService}/files/partner-cac/`;


}
//initialize the endpoint to be available in the object (AppEndpoint)
AppEndpoint.configureEndpoint()


export default AppEndpoint;
