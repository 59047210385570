import React, {useRef, useState} from "react";
import {connect} from "react-redux";
import {changePassword, generatePin, resendPin} from "../../actions/authAction";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import OtpForm from "../../components/otpForm/OtpForm";
import Timer from "../../components/timer/Timer";


const Settings = ({signup_info, generatePin, loading, changePassword, resendPin}) => {

    const [formData, setFormData] = useState({email: signup_info?.email, password: ''})
    const [peek, setPeek] = useState(false);
    const [isResend, setResend] = useState(true)
    const ref = useRef(null)

    const handleModalOpen = () => {
        ref.current.click()
    }

    return (
        <div style={{color: '#747474'}} className='w-100 d-flex align-items-center justify-content-center p-sm-2'>
            <div className=' bg-white px-5 py-4 w-100'>
                <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3'>Change password</div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            generatePin(formData, handleModalOpen)
                            // handleLogin(formData)
                        }}>
                            <div className="form-group w-100 mr-1">
                                <label style={{color: '#747474'}} className='mt-3 mb-1'>Email</label>
                                <input readOnly type="email" className="form-control"  placeholder="Email" defaultValue={formData.email} required />
                            </div>
                            <div className="form-group w-100 mr-1" style={{position: 'relative'}}>
                                <label style={{color: '#747474'}} className='mt-3 mb-1'>New Password</label>
                                <input type={peek ? 'text' : 'password'} className="form-control" defaultValue={formData.password}  placeholder="Password" onChange={(e) => setFormData({...formData, password: e.target.value})} required  />
                                <FontAwesomeIcon onClick={() => setPeek(!peek)} icon={peek ? faEyeSlash : faEye} style={{fontSize: '15px', position: 'absolute', right: '15px', bottom: '10px', cursor: 'pointer'}} />

                            </div>
                            <button disabled={loading} type="submit"  className="btn btn-primary w-100 mt-5 appBg-button">Submit</button>
                        </form>
                    </div>

                </div>
            </div>


            {/*modal*/}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3 text-center'>Enter pin</div>
                            <div className='text-center'>
                                Enter the 4 - digits code sent to {signup_info?.email}
                            </div>
                            <OtpForm loading={loading} onSubmit={(value) => changePassword({...formData, verification_pin: value}, value)} />
                            <div className='mt-3 text-center'>
                                Didn’t receive a code? <span style={{color: '#2096D9'}}> <button disabled={isResend} onClick={() => resendPin(signup_info, setResend)} style={{color: '#2096D9'}}  className="btn btn-link text-decoration-none">Resend in</button></span>
                            </div>
                            <Timer setResend={setResend} />
                        </div>
                        {/*<div className="modal-footer">*/}
                        {/*    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*/}
                        {/*    <button type="button" className="btn btn-primary">Save changes</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" />


        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        generatePin: (data, openModal) => dispatch(generatePin(data, openModal)),
        changePassword: (data, pin) => dispatch(changePassword(data, pin)),
        resendPin: (data, setResend) => dispatch(resendPin(data, setResend)),
    };
}

const mapStateToProps = (state) => ({
    signup_info: state.auth.signup_info,
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

