import {connect} from "react-redux";
import {
    acceptDriverInvite,
    getDriver,
    getDriverPayout, getDriverPayoutCount,
    getDriverRatingAverage,
    sendDriverRequest, updateDriverPayoutMethod
} from "../../actions/driversAction";
import React, {useEffect, useRef, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import AsyncSelectComponent from "../../components/selectComponent/AsyncSelectComponent";
import {assignVehicle, revokeVehicle} from "../../actions/vehiclesAction";
import {NotificationManager} from "react-notifications";
import AboutTab from "./components/AboutTab";
import IdentityTab from "./components/IdentityTab";
import RevenueTab from "./components/RevenueTab";
import PaymentTab from "./components/PaymentTab";

const  Driver = (props) => {

    const {
        driver,
        getDriver,
        assignVehicle,
        revokeVehicle,
        getDriverRatingAverage,
        rating,
        acceptDriverInvite,
        sendDriverRequest,
        signup_info,
        getDriverPayoutCount,
        getDriverPayout,
        driverPayoutCount,
        driverPayout,
        updateDriverPayoutMethod,
        loading
    } = props


    const params = useParams();
    const  navigate = useNavigate();
    const ref = useRef(null)
    const ref2 = useRef(null)

    const [tab, setTab] = useState(1)
    const [data, setData] = useState({vehicle_id: '', driver_id: ''})

    useEffect(() => {
        if(params?.id) {
            getDriver(params?.id)
            getDriverRatingAverage(params?.id)
            getDriverPayout();
            getDriverPayoutCount()
        }
    },[params?.id])

    const handleAssign = () => {
        if(!data.vehicle_id) {
            return NotificationManager.error('select vehicle')
        }
        assignVehicle({...data, driver_id: driver?.auth_id}, handleModalToggle)
    }

    const handleModalToggle = () => {
        ref.current.click()
    }

    const handleModalRevokeToggle = () => {
        ref2.current.click()
    }


    return (
      <div className='pb-5'>
         <div style={{background: '#0B6699', borderRadius: '5px'}} className='py-4'>
             <div className='row'>
                <div className='col-sm-6 px-5'>
                 <div className='d-flex align-items-center text-white row'>
                     <div className='col-lg-3'>
                         <img src={driver?.user_data?.avatar ? driver?.user_data?.avatar : require('../../assets/images/avatar2.png')} alt="" style={{height: '137px', borderRadius: '10px', width: '120px'}} />
                     </div>
                     <div className='px-3 col-lg-9 mt-4 mt-md-0'>
                         <div className='d-flex align-items-center'>
                             <div>{driver?.user_data?.first_name} {driver?.user_data?.last_name}</div>
                             <div className='px-4'>
                                 <div className='d-flex align-items-center'>
                                     <img src={require('../../assets/images/rating_icon.png')} alt="" style={{width: '15px'}} />
                                     <div className='px-2'>{rating}</div>
                                 </div>
                             </div>
                         </div>
                        <div className='row w-100'>
                            <div className='col-sm-12'>
                                <div className='mt-1'>{driver?.user_data?.email}</div>
                            </div>
                        </div>
                         <div className='row mt-1'>
                             <div className='col-sm-6 fw-bold'>Phone number</div>
                             <div className='col-sm-6'>{driver?.user_data?.phone_number}</div>
                         </div>
                         <div className='row mt-1'>
                             <div className='col-sm-6 fw-bold'>State of origin</div>
                             <div className='col-sm-6'>NA</div>
                         </div>
                         <div className='row mt-1'>
                             <div className='col-sm-6 fw-bold'>DOB(ddmmyy)</div>
                             <div className='col-sm-6'>{driver?.user_data?.dob}</div>
                         </div>
                     </div>
                 </div>
                </div>
                 <div className='col-sm-6 px-5 d-flex align-items-center justify-content-end text-white mt-4 mt-md-0'>
                     <div className='d-flex align-items-center justify-content-center' style={{height: '150px', width: '150px', borderRadius: '50%', background: driver?.driver_status === 2 ? '#26CF92' : '#FFF2CF'}}>
                        <div className='text-uppercase'>
                            {driver?.driver_status === 2 ? 'Approved' : 'Pending'}
                        </div>
                     </div>
                 </div>
                <div className="px-5">
                    {driver?.partnership_requests?.length > 0 && driver?.partnership_requests[0].receiver_id === signup_info?.auth_id && driver?.partnership_requests[0].status === 0 &&
                    <div className='mt-3 text-warning'>
                        <div style={{fontSize: '18px'}} >
                            This driver sent you a request
                        </div>
                      <div className='mt-2'>
                          <button onClick={() => acceptDriverInvite(driver?.partnership_requests[0].invite_id, '1', driver?.auth_id)}  type="button"  className='btn btn-success'>
                              Accept
                          </button>
                          <button onClick={() => acceptDriverInvite(driver?.partnership_requests[0].invite_id, '3', driver?.auth_id)}  type="button"  className='btn btn-danger'  style={{marginLeft: '10px'}}>
                              Reject
                          </button>
                      </div>
                    </div>
                    }

                    {driver?.partnership_requests?.length === 0 &&
                        <div className='mt-3 d-flex align-items-center'>
                            <button onClick={() => sendDriverRequest({auth_id: driver?.user_data?.auth_id})}  type="button"  className='btn btn-success'>
                                Send Request
                            </button>
                        </div>
                    }

                    {driver?.partnership_requests?.length > 0 && driver?.partnership_requests[0].sender_id === signup_info?.auth_id && driver?.partnership_requests[0].status === 0 &&
                        <div className='mt-3 text-warning'>
                        <div style={{fontSize: '18px'}} >
                            You sent this driver a request
                        </div>
                        <button onClick={() => acceptDriverInvite(driver?.partnership_requests[0].invite_id, '2')}  type="button"  className='btn btn-danger mt-2'>
                            Cancel Request
                        </button>
                    </div>
                    }

                    {driver?.partnership_status === 2 &&
                        <button  type="button"  className={`btn ${driver?.vehicle_id ? 'btn-danger' : 'btn-primary'} mt-3`} data-bs-toggle="modal" data-bs-target={driver?.vehicle_id ? '#staticBackdropRevoke' : '#staticBackdropAssign'}>
                        {driver?.vehicle_id ? 'Revoke vehicle' : 'Assign vehicle'}
                    </button>
                    }

                </div>
             </div>

         </div>

          {/*Tabs headers*/}
          <div className='px-3 overflow-scroll' style={{minHeight: '80vh'}}>
              <div className='w-100 mt-5 d-flex px-3' style={{borderBottom: '1px solid #DEDEDE'}}>
                  <div className='px-4'>
                      <div onClick={() => setTab(1)} role="button" className='py-2' style={{borderBottom: tab === 1 ? '2px solid #2096D9' : '', color: tab === 1 ? '#2096D9' : 'inherit'}}>About</div>
                  </div>
                  <div className='px-4' role="button">
                      <div onClick={() => setTab(2)} role="button" className='py-2' style={{borderBottom: tab === 2 ? '2px solid #2096D9' : '', color: tab === 2 ? '#2096D9' : 'inherit'}}>Identity</div>
                  </div>
                  <div className='px-4' role="button">
                      <div onClick={() => setTab(3)} role="button" className='py-2' style={{borderBottom: tab === 3 ? '2px solid #2096D9' : '', color: tab === 3 ? '#2096D9' : 'inherit'}}>Revenue</div>
                  </div>
                  <div className='px-4' role="button">
                      <div onClick={() => setTab(4)} role="button" className='py-2' style={{borderBottom: tab === 4 ? '2px solid #2096D9' : '', color: tab === 4 ? '#2096D9' : 'inherit'}}>Payment</div>
                  </div>
              </div>

          {/* Tabs contents*/}
              {tab === 1 && <AboutTab data={driver } />}
              {tab === 2 && <IdentityTab data={driver} />}
              {tab === 3 && <RevenueTab data={driver} />}
              {tab === 4 && <PaymentTab data={driver} loading={loading} updateDriverPayoutMethod={updateDriverPayoutMethod} />}
              <button onClick={() => navigate(-1)}  type="button" className="btn btn-primary mt-5 float-end mb-5 d-flex align-items-center justify-content-center appBg-button">
                  <FontAwesomeIcon className="sidebarIcon" icon={faArrowLeft} />
                  Go back
              </button>

          </div>




          {/*assign vehicle Modal*/}
          <div className="modal fade" id="staticBackdropAssign" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header" style={{border: 'none'}}>
                          <h5 style={{color: '#2096D9'}} className="modal-title">Assign Vehicle</h5>
                          <button ref={ref} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className='p-4'>
                          <AsyncSelectComponent onChange={(value) => {
                              setData({...data, vehicle_id: value.value.vehicle_id})
                          }} />
                      </div>

                     <div className='px-3 py-4'>
                         <button onClick={() => handleAssign()}  type="button"  className="btn btn-success mt-3 float-end">
                             Submit
                         </button>
                     </div>
                  </div>
              </div>
          </div>


          {/*revoke vehicle Modal*/}
          <div className="modal fade" id="staticBackdropRevoke" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header" style={{border: 'none'}}>
                          <h5 style={{color: '#2096D9'}} className="modal-title">Assign Vehicle</h5>
                          <button ref={ref2} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className='p-4'>
                          This will revoke vehicle from the driver. Do you wish to continue?
                      </div>

                      <div className='px-3 py-4'>
                          <button onClick={() => revokeVehicle({vehicle_id: driver?.vehicle_id, driver_id: driver?.auth_id}, handleModalRevokeToggle)}  type="button"  className="btn btn-success mt-3 float-end">
                              Continue
                          </button>
                      </div>
                  </div>
              </div>
          </div>

      </div>
  );
}

function mapDispatchToProps(dispatch) {
    return {
        getDriver: (driver_id) => dispatch(getDriver(driver_id)),
        assignVehicle: (data, closeModal) => dispatch(assignVehicle(data, closeModal)),
        revokeVehicle: (data, closeModal) => dispatch(revokeVehicle(data, closeModal)),
        getDriverRatingAverage: (driver_id) => dispatch(getDriverRatingAverage(driver_id)),
        acceptDriverInvite: (invite_id, status, driver_id) => dispatch(acceptDriverInvite(invite_id, status, driver_id)),
        sendDriverRequest: (data) => dispatch(sendDriverRequest(data)),
        getDriverPayout: () => dispatch(getDriverPayout()),
        getDriverPayoutCount: () => dispatch(getDriverPayoutCount()),
        updateDriverPayoutMethod: (data) => dispatch(updateDriverPayoutMethod(data)),
    };
}

const mapStateToProps = (state) => ({
    signup_info: state.auth.signup_info,
    driver: state.drivers.driverDetails,
    driversCount: state.drivers.driversCount,
    driverPayout: state.drivers.driverPayout,
    driverPayoutCount: state.drivers.driverPayoutCount,
    rating: state.drivers.rating,
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Driver);
