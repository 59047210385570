import {AUTH, EXIST, FORGOT_PASSWORD_EMAIL, LOG_OUT, SETTING, SIGNUP_INFO} from "../actions/types";

const userInfo = localStorage.getItem('ud_partner')


const INIT_STATE = {
    signup_info: userInfo ? JSON.parse(userInfo) : {},
    isAuth: localStorage.getItem('token'),
    setting: {},
    forgot_password_email: '',
    user_exist: {}
};

const authReducer = (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case SIGNUP_INFO:
            return { ...state, signup_info: payload};
        case LOG_OUT:
            return INIT_STATE
        case AUTH:
            return { ...state, isAuth: payload};
        case SETTING:
            return { ...state, setting: payload};
        case FORGOT_PASSWORD_EMAIL:
            return { ...state, forgot_password_email: payload};
        case EXIST:
            return { ...state, user_exist: payload};
        default: return { ...state };
    }
}

export default authReducer
