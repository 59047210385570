import {
    DRIVER,
    DRIVER_RATING,
    DRIVERS,
    DRIVERS_COUNT, DRIVERS_INACTIVE_COUNT,
    DRIVERS_PAYOUT,
    DRIVERS_PAYOUT_COUNT,
} from "../actions/types";



const INIT_STATE = {
   drivers: [],
    driversCount: 0,
    driverDetails: {},
    rating: 0,
    driverPayout: [],
    driverPayoutCount: 0,
    driversInactiveCount: 0
};

const driversReducer = (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case DRIVERS:
            return { ...state, drivers: payload};
        case DRIVERS_COUNT:
            return { ...state, driversCount: payload};
        case DRIVERS_INACTIVE_COUNT:
            return { ...state, driversInactiveCount: payload};
        case DRIVER:
            return { ...state, driverDetails: payload};
        case DRIVER_RATING:
            return { ...state, rating: payload};
        case DRIVERS_PAYOUT:
            return { ...state, driverPayout: payload};
        case DRIVERS_PAYOUT_COUNT:
            return { ...state, driverPayoutCount: payload};
        default: return { ...state };
    }
}

export default driversReducer
