import React, {useState} from "react";
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons'
import SessionLayout from "../../layouts/SessionLayout";
import {changePassword} from "../../actions/authAction";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation} from "react-router-dom";
import {NotificationManager} from "react-notifications";


const CreatePassword = ({loading, changePassword}) => {
    const params = useLocation();

    const [formData, setFormData] = useState({email: params.state.email, password: ''})
    const [confirmPassword, setConfirmPassword] = useState('')
    const [peek, setPeek] = useState(false);
    const [peek2, setPeek2] = useState(false);


    const onSubmit = (e) => {
        e.preventDefault();

        if (formData?.password.length < 8) {
            return NotificationManager.error('Password must be 8 or more characters');
        }
        //check if there's capital letter
        if (!/[A-Z]/.test(formData?.password)) {
            return NotificationManager.error('Password must have at least a capital letter');
        }
        if (!/[a-z]/.test(formData?.password)) {
            NotificationManager.error('Password must have at least a small letter');
        }
        if (!/[0-9]/.test(formData?.password)) {
            return  NotificationManager.error('Password must have at least a number');
        }
        if(formData.password !== confirmPassword) {
            return NotificationManager.error('Passwords do not match')
        }
        changePassword(formData, params.state.pin)
    }


    console.log(params, 'kkkkkkkkkkkkk');

    return (
        <SessionLayout title='Enter new password'>
            <form onSubmit={onSubmit}>
                <div className="form-group w-100 mr-1" style={{position: 'relative'}}>
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Mew Password</label>
                    <input type={peek ? 'text' : 'password'} className="form-control" defaultValue={formData.password}  placeholder="Password" onChange={(e) => setFormData({...formData, password: e.target.value})} required  />
                    <FontAwesomeIcon onClick={() => setPeek(!peek)} icon={peek ? faEyeSlash : faEye} style={{fontSize: '15px', position: 'absolute', right: '15px', bottom: '10px', cursor: 'pointer'}} />

                </div>
                <div className="form-group w-100 mr-1" style={{position: 'relative'}}>
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Confirm Password</label>
                    <input type={peek2 ? 'text' : 'password'} className="form-control" defaultValue={confirmPassword}  placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} required  />
                    <FontAwesomeIcon onClick={() => setPeek2(!peek2)} icon={peek2 ? faEyeSlash : faEye} style={{fontSize: '15px', position: 'absolute', right: '15px', bottom: '10px', cursor: 'pointer'}} />

                </div>
                <button disabled={loading} type="submit" className="btn btn-primary w-100 mt-5 appBg-button">Submit</button>
            </form>
        </SessionLayout>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        changePassword: (data, pin) => dispatch(changePassword(data, pin)),
    };
}

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
