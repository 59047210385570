import React from "react";

const RevenueTab = ({data}) => {
    return (
        <div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Revenue Percentage
                </div>
                <div className='col-sm-8'>
                    40% of Net Earnings
                </div>
            </div>

        </div>
    )
}

export default RevenueTab
