import React from "react";

const SessionLayout = ({children, title}) => {
    return (
        <div style={{background: '#F9F9F9', height: '100vh'}}>
            <div className="topLeft px-3">
          <span className="logo">
                      <img src={require('../assets/images/Logo.png')} alt="lagosride logo" style={{width: '150px'}}  />
          </span>
            </div>

            <div style={{minHeight: '90%', background: '#F9F9F9', color: '#747474'}} className='align-items-center justify-content-center d-flex p-3 p-md-5'>
                <div className=' bg-white px-5 py-4 formContainer' style={{borderRadius: '10px'}}>
                    <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3'>{title}</div>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SessionLayout
