import React from "react";

const IdentityTab = ({data}) => {
    return (
        <div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Drivers’ Licence
                </div>
                <div className='col-sm-8'>
                    {data?.license_id?.value}
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    NIN
                </div>
                <div className='col-sm-8'>
                    {data?.nin_id?.value}
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    LASSRI ID
                </div>
                <div className='col-sm-8'>
                    {data?.lasdri_id?.value}
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    LASSRA ID
                </div>
                <div className='col-sm-8'>
                    {data?.lassra_id?.value}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Certificate
                </div>
                <div className='col-sm-8'>
                    N/A
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    LADRI Training result
                </div>
                <div className='col-sm-8'>
                    N/A
                </div>
            </div>
        </div>
    )
}

export default IdentityTab
