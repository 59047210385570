import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import { userData } from "../../dummyData";
import { connect } from "react-redux";
import { getDriversCount, getDriversCountInactive, getRequestedDriversCount } from "../../actions/driversAction";
import { useEffect, useState } from "react";
import { getVehiclesCount } from "../../actions/vehiclesAction";
import { getRevenueBalance, getRevenueMonthly, getWalletBalance } from "../../actions/earningsAction";

const Home = ({
   signup_info,
   getDriversCount,
   driversCount,
   getDriversCountInactive,
   driversInactiveCount,
   getVehiclesCount,
   vehiclesCount,
   walletBalance,
   getWalletBalance,
   getRevenueBalance,
   revenueBalance,
   getRevenueMonthly,
   revenueMonthly
}) => {
   const [chartData, setChartData] = useState([])

   useEffect(() => {
      // getDriversCount()
      // getDriversCountInactive()
      getVehiclesCount()
      getWalletBalance()
      getRevenueBalance()
      getRevenueMonthly()
   }, [])

   const getChartGross = () => {
      let gross = userData.map((e, i) => {
         let temp = revenueMonthly.find(element => (element.createdAt).split('-')[1] == e.id)
         if (temp) {
            e.earning = temp.partner_earning
         }
         return e;
      })

      setChartData(gross)

   }


   useEffect(() => {
      if (revenueMonthly.length > 0) {
         getChartGross()
      }
   }, [revenueMonthly.length])





   return (
      <div className="home">
         <FeaturedInfo
            driversCount={driversCount}
            driversInactiveCount={driversInactiveCount}
            vehiclesCount={vehiclesCount}
            walletBalance={walletBalance}
            revenueBalance={revenueBalance}
         />
         <Chart data={chartData} title="Your Earnings" grid dataKey="earning" />
      </div>
   );
}

function mapDispatchToProps(dispatch) {
   return {
      getDriversCount: () => dispatch(getDriversCount()),
      getDriversCountInactive: () => dispatch(getDriversCountInactive()),
      getVehiclesCount: () => dispatch(getVehiclesCount()),
      getWalletBalance: () => dispatch(getWalletBalance()),
      getRevenueMonthly: () => dispatch(getRevenueMonthly()),
      getRevenueBalance: () => dispatch(getRevenueBalance()),
   };
}

const mapStateToProps = (state) => ({
   signup_info: state.auth.signup_info,
   loading: state.loading.loading,
   driversCount: state.drivers.driversCount,
   driversInactiveCount: state.drivers.driversInactiveCount,
   vehiclesCount: state.vehicles.vehiclesCount,
   walletBalance: state.earnings.walletBalance,
   revenueBalance: state.earnings.revenueBalance,
   revenueMonthly: state.earnings.revenueMonthly
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
