const  Support = () => {


  return (
      <div className="table-responsive" style={{minHeight: "50vh"}}>
          <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3'>Support</div>
          <div className='text-center'>Coming soon</div>

      </div>
  );
}

export default Support
