import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { connect } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#2096D9' : '#308fe8',
    },
}));

const ProgressBar = ({loading}) => {
    return loading ?
        (
            <div style={{position: 'fixed', top: 0, width: '100%', zIndex: 9999999999999}}>
                <BorderLinearProgress />
            </div>
        ): null
}

function mapDispatchToProps(dispatch) {
    return {};
}

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBar);
