import React from "react";

const AboutTab = ({data}) => {
    return (
        <div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Education
                </div>
                <div className='col-sm-8'>
                    {data?.user_data?.education}
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Facial marks
                </div>
                <div className='col-sm-8'>
                    {data?.facial_mark === 1 ? 'Yes' : 'No'}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Eye glasses
                </div>
                <div className='col-sm-8'>
                    {data?.eye_glasses === 1 ? 'Yes' : 'No'}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Disability
                </div>
                <div className='col-sm-8'>
                    {data?.disability === 1 ? 'Yes' : 'No'}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Home address
                </div>
                <div className='col-sm-8'>
                    {data?.user_data?.home_address}
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Experience
                </div>
                <div className='col-sm-8'>
                    {data?.year_exp}year(s)
                </div>
            </div>
            <div className='mt-5' style={{color: '#2096D9'}}>NEXT OF KIN DETAILS </div>

            <div className='row mt-2'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Next of Kin
                </div>
                <div className='col-sm-8'>
                    {data?.next_kin?.name}
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Relationship with Next of Kin
                </div>
                <div className='col-sm-8'>
                    {data?.next_kin?.relationship}
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-sm-4' style={{color: '#51626B'}}>
                    Phone number of Next of Kin
                </div>
                <div className='col-sm-8'>
                    {data?.next_kin?.phone_number}
                </div>
            </div>
        </div>
    )
}
export default AboutTab
