import {
    AUTH,
    AVAILABLE_VEHICLES_COUNT,
    DRIVER,
    DRIVERS,
    DRIVERS_COUNT,
    SIGNUP_INFO,
    VEHICLES,
    VEHICLES_COUNT
} from "../actions/types";



const INIT_STATE = {
   vehicles: [],
    vehiclesCount: 0,
    availableVehiclesCount: 0
};

const vehiclesReducer = (state = INIT_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case VEHICLES:
            return { ...state, vehicles: payload};
        case VEHICLES_COUNT:
            return { ...state, vehiclesCount: payload};
        case AVAILABLE_VEHICLES_COUNT:
            return { ...state, availableVehiclesCount: payload};
        default: return { ...state };
    }
}

export default vehiclesReducer
