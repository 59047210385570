import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap";
import "./App.css";
import 'react-notifications/lib/notifications.css';
import {NotificationContainer} from "react-notifications";
import {Provider} from "react-redux";
import {configureStore} from "./store";
import Home from "./pages/home/Home";
import {
  BrowserRouter,
  Routes,
  Route,
    Navigate,
} from "react-router-dom";
import Drivers from "./pages/drivers/Drivers";
import Login from "./pages/onboarding/Login";
import Signup from "./pages/onboarding/Signup";
import CompleteProfile from "./pages/onboarding/CompleteProfile";
import DashboardLayout from "./layouts/DashboardLayout";
import ProgressBar from "./components/loader/ProgressBar";
import VerifyOtp from "./pages/onboarding/VerifyOtp";
import axios from "axios";
import Vehicles from "./pages/vehicles/Vehicles";
import Payments from "./pages/payments/Payments";
import Support from "./pages/support/Support";
import Settings from "./pages/settings/Settings";
import Driver from "./pages/drivers/Driver";
import DriversInvite from "./pages/drivers/DriversInvite";
import ForgetPassword from "./pages/onboarding/ForgetPassword";
import CreatePassword from "./pages/onboarding/CreatePassword";
import NotFound from "./pages/onboarding/NotFound";
import Cashout from "./pages/cashOut/Cashout";


axios.interceptors.request.use(async (config) => {
    try {
        let token = localStorage.getItem('token');

        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    } catch (e) {
        console.log(e);
    }
});


axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.clear()
            // eslint-disable-next-line no-restricted-globals
            location.replace("/login");
        }
        return Promise.reject(error);
    }
);

const ProtectedRoute = ({user, redirectPath = '/login', children,}) => {
    if (!user) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <DashboardLayout />;
};



function App({user = true}) {

  return (
      <Provider store={configureStore}>
        <ProgressBar />
          <NotificationContainer />
        <BrowserRouter>
            <Routes>
                <Route  path="/" element={<ProtectedRoute user={configureStore.getState().auth.isAuth} />} >
                    <Route exact path="/" element={<Home />}/>
                    <Route exact path="drivers/invites" element={ <DriversInvite />} />
                    {/*<Route exact path="drivers/:id" element={ <Driver />} />*/}
                    {/*<Route exact path="drivers" element={ <Drivers />} />*/}
                    <Route exact path="vehicles/:id" element={ <Vehicles />} />
                    <Route exact path="vehicles" element={ <Vehicles />} />
                    <Route exact path="payments" element={ <Payments />} />
                    <Route exact path="cashout" element={ <Cashout />} />
                    <Route exact path="support" element={ <Support />} />
                    <Route exact path="profile" element={ <CompleteProfile />} />
                    <Route exact path="settings" element={ <Settings />} />
                </Route>
                <Route exact path="/login" element={ <Login />} />
                <Route exact path="/register" element={ <Signup />} />
                <Route exact path="/verify-otp" element={ <VerifyOtp />} />
                <Route exact path="/forgot-password" element={ <ForgetPassword />} />
                <Route exact path="/create-password" element={ <CreatePassword />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
