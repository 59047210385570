import {LOADING_END, LOADING_START} from "./types";

export const startLoading = () => dispatch => {
        dispatch({
        type: LOADING_START,
        });
};

export const endLoading = () => dispatch => {
        dispatch({
                type: LOADING_END,
        });
};


