import "./featuredInfo.css";
import { formatMoney } from "../../helpers/helpers";
import React from "react";
import { getWalletDva } from "../../actions/earningsAction";

export default function FeaturedInfo({ vehiclesCount, walletBalance, revenueBalance }) {

   const [wAcc, setWAcc] = React.useState({})
   React.useEffect(() => {
      getWalletDva(res => {
         console.log(res, "result")
         //if the data retrieves
         if (res && res.data instanceof Array && res.data.length > 0) {
            setWAcc(res.data[0])
         }
      })
   }, [])

   return (
      <div>
         <div className=" row" style={{ margin: '1px' }}>
            <div className="featuredItem col-sm-3 m-2 m-md-0">
               <span className="featuredTitle">Total Earnings </span>
               <div className="featuredMoneyContainer">
                  <span className="featuredMoney">₦{formatMoney(revenueBalance?.net_earning)}</span>
                  <span className="featuredMoneyRate">
                  </span>
               </div>
            </div>
            <div className="featuredItem col-sm-3 m-2 m-md-0">
               <span className="featuredTitle">My Earnings</span>
               <div className="featuredMoneyContainer">
                  <span className="featuredMoney">₦{formatMoney(walletBalance?.partner_earning)}</span>
                  <span className="featuredMoneyRate">
                  </span>
               </div>
            </div>
            <div className="featuredItem col-sm-3 m-2 m-md-0">
               <span className="featuredTitle">Wallet Balance</span>
               <div className="featuredMoneyContainer">
                  <span className="featuredMoney">₦{formatMoney(walletBalance?.balance)}</span>
               </div>
               {wAcc?.account_number && <p>
                  <div></div>
                  <span style={{ textTransform: "capitalize" }} className=""><strong>Account:</strong> {wAcc?.account_number}</span>
                  <div style={{ textTransform: "capitalize" }} className=""><strong>Bank:</strong> {wAcc?.bank_name}</div>
                  <div style={{ textTransform: "capitalize" }} className=""><strong>Name:</strong> {wAcc?.account_name}</div>
               </p>
               }
            </div>
            <div className="featuredItem col-sm-3 m-2 m-md-0">
               <span className="featuredTitle">Total number of vehicle</span>
               <div className="featuredMoneyContainer">
                  <span className="featuredMoney">{vehiclesCount}</span>
                  <span className="featuredMoneyRate">
                  </span>
               </div>
            </div>
         </div>
      </div>
   );
}
