import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {connect} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {cancelVehicleInterest, getSetting, updateProfile} from "../../actions/authAction";
import {getVehicles, getVehiclesCount} from "../../actions/vehiclesAction";
import Pagination from "react-js-pagination";
import {getDriver} from "../../actions/driversAction";
import {NotificationManager} from "react-notifications";

const  Vehicles = (props) => {

    const {
        signup_info,
        cancelVehicleInterest,
        loading,
        handleUpdate,
        vehicles,
        getVehicles,
        vehiclesCount,
        getVehiclesCount,
        getDriver,
        driver
    } = props


    const ref = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref3 = useRef(null)
    const refHide = useRef(null)
    const refHide4 = useRef(null)
    const refHide3 = useRef(null)
    const [formData, setFormData]  = useState({ vehicle_interested: {unit: '', amount: '0', has_driver: ''}})
    // const [formData2, setFormData2]  = useState({ have_driver: '', equity_amount: ''})
    const [vehicleDetails, setVehicleDetails] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    // const [vehiclesPending, setVehiclesPending] = useState({})
    const filteredVehicles = signup_info?.partner_data?.vehicle_interested?.filter(x => x.status === 0)

    const {vehicle_interested} = formData



    useEffect(() => {
        getVehicles()
        getVehiclesCount()
    },[])

    useEffect(() => {
        if(vehicleDetails?.driver_auth_id) {
            getDriver(vehicleDetails?.driver_auth_id)
        }
    },[vehicleDetails?.driver_auth_id])

    const handleModalOpen = () => {
        ref.current.click()
    }
    const handleModalOpen4 = () => {
        ref4.current.click()
    }
    const handleModalOpen5 = () => {
        ref5.current.click()
    }
    const handleModalHide = () => {
        refHide.current.click()
    }
    const handleModalHideVehicle = () => {
        refHide3.current.click()
    }

    const handleVehiclesDetailsModalOpen = (data) => {
        setVehicleDetails(data)
        ref3.current.click()
    }


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        getVehicles(pageNumber);
    };




    return (
      <div className='px-3'>
          <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3'>Vehicles</div>
          <div className='d-flex justify-content-between mb-2'>
              {filteredVehicles?.length ?
                  <div className='d-flex align-items-center'>
                      <div>You have pending payments for <span style={{color: '#2096D9'}}>{filteredVehicles[0]?.unit} vehicles</span></div>
                      {/*<button  type="button" className="btn btn-danger"   onClick={() => handleModalOpen()} style={{marginLeft: '20px'}}>Pay Now</button>*/}
                  </div> : <div />

              }
              <button  type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" style={{fontSize: '100%', padding: '5px'}}>+ New Vehicle</button>
          </div>
              <>
              <div className="table-responsive" style={{minHeight: "50vh"}}>
                  {/*<div>you have </div>*/}
                  <Table>
                      <TableHead>
                          <TableRow style={{background: '#DCFFFD'}} hover>
                              <TableCell>Action</TableCell>
                              <TableCell>Plate No</TableCell>
                              <TableCell>Serial No</TableCell>
                              <TableCell>Model</TableCell>
                              <TableCell>Year</TableCell>
                              <TableCell>Status</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          <>{
                              filteredVehicles?.length > 0 && filteredVehicles.map((filtered, key) => (
                                  <TableRow className='bg-white' hover key={key}>
                                      <TableCell>
                                          <button  type="button" className="btn btn-secondary"  style={{background: '#2096D9', border: '1px solid #2096D9'}}  onClick={() => handleModalOpen4()}>Pay Now</button>
                                      </TableCell>
                                      <TableCell>NA</TableCell>
                                      <TableCell>NA</TableCell>
                                      <TableCell>NA</TableCell>
                                      <TableCell>NA</TableCell>
                                      <TableCell>
                                          NA
                                      </TableCell>
                                  </TableRow>
                              ))
                          }
                              {vehicles?.length > 0 && vehicles?.map((vehicle, key) => (
                                  <TableRow className='bg-white' hover key={key}>
                                      <TableCell>
                                          <button onClick={() => handleVehiclesDetailsModalOpen(vehicle)} style={{background: '#2096D9', border: '1px solid #2096D9'}} className='btn btn-primary'>
                                              View
                                          </button>
                                      </TableCell>
                                      <TableCell>{vehicle.car_number_plate}</TableCell>
                                      <TableCell>{vehicle.car_number}</TableCell>
                                      <TableCell>{vehicle.car_make}</TableCell>
                                      <TableCell>{vehicle.car_model}</TableCell>
                                      <TableCell>
                                          <div className='px-2 py-1' style={{background: vehicle?.assigned ? 'rgba(190, 252, 174, 0.5)' : 'rgba(252, 230, 174, 0.5)', borderRadius: '5px', width: 'fit-content'}}>
                                          {vehicle?.assigned ? 'Active' : 'Inactive'}
                                      </div>
                                      </TableCell>
                                  </TableRow>
                              ))}
                          </>
                      </TableBody>
                  </Table>
                  {vehicles?.length > 0 && <div className="d-flex justify-content-end align-items-center mb-0 mt-3 mr-2">
                      <Pagination activeLinkClass='info'  activePage={currentPage} itemClass="page-item" linkClass="page-link" itemsCountPerPage={20} totalItemsCount={vehiclesCount} onChange={paginate} />
                  </div>}
                  {!loading && vehicles?.length === 0 && filteredVehicles?.length === 0 &&
                      <div className='text-center mt-5'>No vehicles available</div>
                  }
              </div>
              </>
              <div>

              </div>




          {/*modal*/}
          <div className="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel4" style={{color: '#2096D9'}}>vehicle Equity payment</h5>
                          <button ref={refHide4} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          <form onSubmit={(e) => {
                              e.preventDefault();
                              if(vehicle_interested?.amount < 140000 && vehicle_interested?.has_driver === 'yes') {
                                  return NotificationManager.error('Amount must be  N140,000 or more')
                              }
                              handleUpdate(formData?.vehicle_interested?.has_driver === 'no' ? {...formData, vehicle_interested: {...formData.vehicle_interested, amount: '140000', unit: filteredVehicles[0]?.unit}} : {...formData, vehicle_interested: {...formData.vehicle_interested, unit: filteredVehicles[0]?.unit}}, null, null, null, handleModalOpen);
                          }}>
                          <div className="form-group">
                              <label style={{color: '#747474'}} className='mt-3 mb-1'>Do you have a driver for this vehicle?</label>
                              <select  className='form-select' value={vehicle_interested?.has_driver} onChange={(e) => setFormData({...formData, vehicle_interested: {...formData.vehicle_interested, has_driver: e.target.value}})} name='has_driver' required>
                                  <option value=''>Select an option</option>
                                  <option value="yes">Yes, I have a driver</option>
                                  <option value="no">No, I dont have a driver</option>
                              </select>
                          </div>
                              <div className="form-group w-100 mr-1">
                                  <label style={{color: '#747474'}} className='mt-3 mb-1'>
                                      Indicate equity payment amount for each vehicle
                                      {vehicle_interested?.has_driver === 'yes' && <div style={{fontStyle: 'italic', fontSize: '14px'}}>Note: You can pay a minimum of 140k and above for each this asset. <span style={{color: '#2096D9', textDecoration: 'underline'}}>Read more</span></div>
                                      }
                                      {vehicle_interested?.has_driver === 'no' && <div style={{fontStyle: 'italic', fontSize: '14px'}}>Note: You are only allowed to pay the sum of 140,000naira to earn 20% daily of the net earnings from the vehicles. <span style={{color: '#2096D9', textDecoration: 'underline'}}>Read more</span></div>
                                      }
                                  </label>
                                  <input disabled={vehicle_interested?.has_driver === 'no'} placeholder={vehicle_interested.has_driver === 'yes' ? 'Min. N140,000' : 'Amount'}  type="number" className="form-control"   value={vehicle_interested.has_driver === 'no' ? '140000' : vehicle_interested.amount}  name='equity_amount' onChange={(e) => setFormData({...formData, vehicle_interested: {...formData.vehicle_interested, amount: e.target.value}})} required />
                              </div>
                              <button disabled={loading} type="submit" className="btn btn-primary w-100 mt-5 appBg-button">Pay Now</button>

                          </form>
                      </div>
                  </div>
              </div>
          </div>
          <button ref={ref4} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop4" />




          <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">Vehicles Interested</h5>
                          <button ref={refHide} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                          {filteredVehicles?.length > 0 &&
                              <div>
                                  <div>
                                      You indicated interest in {filteredVehicles[0].unit} vehicle(s)
                                  </div>
                                  <div className='mt-1'>
                                      {/*Pay ₦{(setting?.com_driver_fee?.total && (setting?.com_driver_fee?.total * filteredVehicles[0].unit * 0.2).toLocaleString())} to the account details below;*/}
                                      Pay ₦{filteredVehicles[0]?.amount?.toLocaleString()} to the account details below;
                                  </div>
                                  <div className='mt-1'>
                                      <span style={{fontWeight: '800'}}>Bank Name:</span>   {signup_info?.payment_data?.bank_name}
                                  </div>
                                  <div className='mt-1'>
                                      <span style={{fontWeight: '800'}}>Account Name:</span>   {signup_info?.payment_data?.account_name}
                                  </div>
                                  <div className='mt-1'>
                                      <span style={{fontWeight: '800'}}>Account Number:</span>   {signup_info?.payment_data?.account_number}
                                  </div>
                              </div>
                          }
                      </div>
                      <div className="modal-footer">
                          {/*<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*/}
                          <button onClick={() => cancelVehicleInterest(handleModalHide, filteredVehicles[0]?._id)} type="button" className="btn btn-danger">Cancel Interest</button>
                      </div>
                  </div>
              </div>
          </div>


          {/*show vehicle interested modal button*/}
          <button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" />




          {/*Add vehicle Modal*/}
          <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header border-0">
                          <h5 className="modal-title">Add Vehicles Interested</h5>
                          <button  type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                          <form onSubmit={(e) => {
                              e.preventDefault();
                              handleUpdate(formData, null, null, null, handleModalOpen5)
                          }}>
                              <div className="modal-body">
                              <div className="form-group w-100 mr-1">
                                  <label style={{color: '#747474'}} className='mt-3 mb-1'>Number of Vehicles interested</label>
                                  <input type="number" className="form-control"  placeholder="Number of Vehicles" value={formData?.vehicle_interested?.unit}  name='vehicle_interested' onChange={(e) => setFormData({...formData, vehicle_interested: {...formData.vehicle_interested, unit: e.target.value}})} required/>
                              </div>
                                  <button disabled={loading} type="submit" style={{background: 'linear-gradient(105.78deg, #2FB5D9 30.68%, #6FDB7A 130.02%)', border: '1px solid', width: 'fit-content'}} className="btn btn-primary mt-5 float-end mb-4">Submit</button>

                              </div>
                              {/*<div className="modal-footer">*/}
                              {/*    /!*<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>*!/*/}
                              {/*    <button onClick={() => cancelVehicleInterest(handleModalHide, signup_info?.partner_data?.vehicle_interested?.length > 0 ? signup_info?.partner_data?.vehicle_interested[0]?._id : null)} type="button" className="btn btn-danger">Cancel Interest</button>*/}
                              {/*</div>*/}
                          </form>

                  </div>
              </div>
          </div>
          <button ref={ref5} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" />


          {/*vehicle details Modal*/}
          <div className="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog">
                  <div className="modal-content">
                      <div className="modal-header" style={{border: 'none'}}>
                          <h5 style={{color: '#2096D9'}} className="modal-title">Vehicle Details</h5>
                          <button ref={refHide3} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className='p-4'>
                          <div className='d-flex justify-content-between'>
                              {/*<div style={{fontSize: '50px'}}>*/}
                              {/*    {vehicleDetails?.car_number}*/}
                              {/*</div>*/}
                              <div>
                                  <div>
                                      <img src={driver?.avatar ? driver?.avatar : require('../../assets/images/avatar.png')} alt="" style={{height: '100px', width: '100px', borderRadius: '50%'}} />
                                  </div>
                                  <div className='text-primary mt-2' style={{fontSize: '20px'}}> {driver?.first_name} {driver?.last_name}</div>
                              </div>
                             <div>
                                 <div className='px-2 py-1' style={{background: vehicleDetails?.assigned ? 'rgba(190, 252, 174, 0.5)' : 'rgba(252, 230, 174, 0.5)', borderRadius: '5px'}}>
                                     {vehicleDetails?.assigned ? 'Active' : 'Inactive'}
                                 </div>
                             </div>
                          </div>
                          <div className='row mt-4' style={{color: '#51626B', borderBottom: '1px solid grey', paddingBottom: '20px'}}>
                             <div className='col-sm-6'>
                                 <div className='row'>
                                     <div className='col-6 fw-bold'>Ratings</div>
                                     <div className='col-6'>{driver?.rating}</div>
                                 </div>
                                 <div className='row'>
                                     <div className='col-6 fw-bold'>Serial no</div>
                                     <div className='col-6'>{vehicleDetails?.car_number}</div>
                                 </div>
                                <div className='row'>
                                    <div className='col-6 fw-bold'>Year</div>
                                    <div className='col-6'>{vehicleDetails?.car_model}</div>
                                </div>
                                 <div className='row mt-1'>
                                     <div className='col-6 fw-bold'>Plate Number</div>
                                     <div className='col-6'>{vehicleDetails?.car_number_plate}</div>
                                 </div>
                                 <div className='row mt-1'>
                                     <div className='col-6 fw-bold'>Model</div>
                                     <div className='col-6'>{vehicleDetails?.car_make}</div>
                                 </div>
                                 <div className='row mt-1'>
                                     <div className='col-6 fw-bold'>Color</div>
                                     <div className='col-6'>{vehicleDetails?.car_color}</div>
                                 </div>
                             </div>
                          </div>
                          <div className='mt-3' style={{color: '#2096D9'}}>
                              QR code
                          </div>
                          <div className='mt-1'>
                              <img src={vehicleDetails.qr_code} alt="" style={{height: '137px', borderRadius: '10px'}} />
                          </div>
                          <a href={vehicleDetails.qr_code}  type="button" className="btn btn-success mt-2" download>Download</a>

                      </div>


                  </div>
              </div>
          </div>
          {/*show vehicle details button*/}
          <button ref={ref3} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop3" />


      </div>
  );
}

function mapDispatchToProps(dispatch) {
    return {
        getSetting: () => dispatch(getSetting()),
        cancelVehicleInterest: (handleHideModal, vehicle_interested_id) => dispatch(cancelVehicleInterest(handleHideModal, vehicle_interested_id)),
        handleUpdate: (data, partner_driver_payment, imgUrl, cacDoc, handleModalHideVehicle) => dispatch(updateProfile(data, partner_driver_payment, imgUrl, cacDoc, handleModalHideVehicle)),
        getVehicles: (page_no) => dispatch(getVehicles(page_no)),
        getVehiclesCount: (page_no) => dispatch(getVehiclesCount()),
        getDriver: (driver_id) => dispatch(getDriver(driver_id)),

    };
}

const mapStateToProps = (state) => ({
    signup_info: state.auth.signup_info,
    setting: state.auth.setting,
    loading: state.loading.loading,
    vehicles: state.vehicles.vehicles,
    vehiclesCount: state.vehicles.vehiclesCount,
    driver: state.drivers.driverDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(Vehicles);
