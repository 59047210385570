import React, {useState} from 'react';




const OtpForm  = ({onSubmit, loading = false}) => {

    const [formData, setFormData] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" })

    const {otp1, otp2, otp3, otp4} = formData


    const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

     const handleSubmit = (event) => {
            event.preventDefault();
            if (typeof onSubmit === "function") {
                onSubmit(otp1+otp2+otp3+otp4)
            }
        }

   const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (next < 5) {
                elmnt.target.form.elements[next].focus()
            }
        }

    }
        return (
            <form onSubmit={handleSubmit}>
                <div className="otpContainer d-flex align-items-center justify-content-center">

                    <input
                        name="otp1"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otp1}
                        onChange={handleChange}
                        tabIndex="1" maxLength="1" onKeyUp={inputfocus}

                    />
                    <input
                        name="otp2"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otp2}
                        onChange={handleChange}
                        tabIndex="2" maxLength="1" onKeyUp={inputfocus}

                    />
                    <input
                        name="otp3"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otp3}
                        onChange={handleChange}
                        tabIndex="3" maxLength="1" onKeyUp={inputfocus}

                    />
                    <input
                        name="otp4"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={otp4}
                        onChange={handleChange}
                        tabIndex="4" maxLength="1" onKeyUp={inputfocus}
                    />
                </div>
                <button disabled={loading} type="submit" style={{background: 'linear-gradient(105.78deg, #2FB5D9 30.68%, #6FDB7A 130.02%)', border: '1px solid'}} className="btn btn-primary w-100 mt-5">Submit</button>
            </form>
        );
}


export default OtpForm;
