import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import SessionLayout from "../../layouts/SessionLayout";
import {verifyUser} from "../../actions/authAction";
import {connect} from "react-redux";


const ForgetPassword = ({verifyUser, loading}) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({email: ''})

    return (
        <SessionLayout title='Forgot Password'>
            <form onSubmit={(e) => {
                e.preventDefault();
                verifyUser(formData, navigate)
            }}>
                <div className="form-group w-100 mr-1">
                    <label style={{color: '#747474'}} className='mt-3 mb-1'>Email</label>
                    <input type="email" className="form-control" defaultValue={formData.email}  placeholder="Enter email to proceed" onChange={(e) => setFormData({...formData, email: e.target.value})} required />
                </div>
                <button disabled={loading} type="submit" className="btn btn-primary w-100 mt-5 appBg-button">Continue</button>
            </form>
            <div className='mt-3'>
                Do not have an account?  <span style={{color: '#2096D9'}}> <Link to="/register" className="link">Sign up</Link></span>
            </div>
        </SessionLayout>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        verifyUser: (data, navigate) => dispatch(verifyUser(data, navigate)),
    };
}

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
