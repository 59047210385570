import React, {useState} from "react";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { v4 as uuidv4 } from 'uuid';
import {partnerInviteDriver} from "../../actions/driversAction";
import {NotificationManager} from "react-notifications";


const DriversInvite = ({loading, partnerInviteDriver}) => {

    const [formData, setFormData] = useState({id: "", first_name: "", last_name: "", email: "", phone_number: ""})
    const [inviteArray, setInviteArray] = useState([])
    const {id, first_name, last_name, email, phone_number} = formData



    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleAdd = () => {
        let index = inviteArray.findIndex(x => x.id === id);
        const newArray = inviteArray
        if(index === -1) {
            newArray.push({...formData, id: uuidv4()})
        } else {
            newArray[index] = formData;
        }
        setInviteArray(newArray)
        setFormData({ id:"", first_name: "", last_name: "", email: "", phone_number: ""})
    }

   const handleDelete = (delete_id) => {
       const filteredArray = inviteArray.filter(item => item.id !== delete_id)
       setInviteArray(filteredArray)
   }

   const handleSubmit = () => {
        if(inviteArray.length === 0) {
            return    NotificationManager.error('Enter driver details to continue')
        }
        if(first_name || last_name || email || phone_number) {
         return    NotificationManager.error('Click add button to add driver details')
        }
       const data = inviteArray.map(({id,index,...rest}) => ({...rest}));
        partnerInviteDriver({invites: data}, setInviteArray)
   }


    return (
        <div style={{color: '#747474'}} className='w-100 d-flex align-items-center justify-content-center p-sm-2'>
            <div className=' bg-white px-5 py-4 w-100'>
                <div style={{color: '#474242', fontSize: '30px', fontWeight: '600'}} className='mb-3'>Invite Driver(s)</div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleAdd();
                            // handleLogin(formData)
                        }}>
                            <div className="form-group w-100 mr-1">
                                <label style={{color: '#747474'}} className='mt-3 mb-1'>First name</label>
                                <input  type="text" className="form-control"  placeholder="First name" name='first_name' value={first_name} onChange={onChange} required />
                            </div>
                            <div className="form-group w-100 mr-1">
                                <label style={{color: '#747474'}} className='mt-3 mb-1'>Last name</label>
                                <input  type="text" className="form-control"   placeholder="First name" name='last_name' value={last_name} onChange={onChange} required />
                            </div>

                            <div className="form-group w-100 mr-1">
                                <label style={{color: '#747474'}} className='mt-3 mb-1'>Email</label>
                                <input  type="email" className="form-control"  placeholder="Email" name='email' value={email} onChange={onChange} required />
                            </div>
                            <div className="form-group w-100 mr-1">
                                <label style={{color: '#747474'}} className='mt-3 mb-1'>Phone number</label>
                                <input  type="number" className="form-control"   placeholder="Phone number" name='phone_number' value={phone_number} onChange={onChange} required />
                            </div>

                            <div className='d-flex align-items-center justify-content-between mt-3'>
                               <div className='d-flex align-items-center'>
                                   {inviteArray.length > 0 &&
                                      <>
                                          <div>{inviteArray.length} driver(s) added</div>
                                          <a type="button" className='btn btn-link text-decoration-none' data-bs-toggle="modal" data-bs-target="#staticBackdrop">View</a>


                                      </>
                                   }
                               </div>
                                <button type="submit" className="btn btn-primary  d-flex align-items-center justify-content-center appBg-button">
                                    <FontAwesomeIcon className="sidebarIcon" icon={faAdd} />
                                    Add
                                </button>
                            </div>
                            {/*<button disabled={loading} type="submit"  className="btn btn-primary w-100 mt-5 appBg-button">Submit</button>*/}
                        </form>
                        <button onClick={() => handleSubmit()} disabled={loading} type="button"  className="btn btn-primary w-100 mt-5 appBg-button">Submit</button>

                    </div>

                </div>
            </div>


            {/*modal*/}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header border-0">
                            {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body border-0" style={{overflowX: 'auto'}}>
                            <Table>
                                <TableHead>
                                    <TableRow style={{background: '#DCFFFD'}} hover>
                                        <TableCell>First name</TableCell>
                                        <TableCell>Last name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone number</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {inviteArray?.length > 0 && inviteArray.map((driver, key) => (
                                            <TableRow className='bg-white' hover key={key}>
                                                <TableCell>{driver?.first_name}</TableCell>
                                                <TableCell>{driver?.last_name}</TableCell>
                                                <TableCell>{driver?.email}</TableCell>
                                                <TableCell>{driver?.phone_number}</TableCell>
                                                <TableCell>
                                                    <FontAwesomeIcon onClick={() => handleDelete(driver.id)} role='button' className='text-danger'  style={{fontSize: '15px', marginRight: '10px'}} icon={faTrash} />
                                                    <FontAwesomeIcon onClick={() => setFormData(driver)} role='button' className='text-primary' style={{fontSize: '15px'}} icon={faEdit} data-bs-toggle="modal" data-bs-target="#staticBackdrop" />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                </TableBody>
                            </Table>

                        </div>
                    </div>
                </div>
            </div>
            {/*<button ref={ref} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" />*/}


        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        partnerInviteDriver: (data, setInviteArray) => dispatch(partnerInviteDriver(data, setInviteArray)),
    };
}

const mapStateToProps = (state) => ({
    loading: state.loading.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(DriversInvite);

