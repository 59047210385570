import axios from "axios";
import { NotificationManager } from "react-notifications";
import AppEndpoint from "../assets/utils/AppEndpoint";
import { WALLET_BALANCE, REVENUE, REVENUE_BALANCE, REVENUE_DAILY, REVENUE_MONTHLY, REVENUE_DAILY_COUNT, CASHOUT, CASHOUT_COUNT } from "./types";
import { endLoading, startLoading } from "./loadingAction";
import { configureStore } from "../store";




export const getWalletBalance = () => async (dispatch) => {

   //change env
   const userInfo = configureStore.getState().auth.signup_info
   AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
   dispatch(startLoading())
   try {
      const res = await axios.get(`${AppEndpoint.WalletService}/wallets/balance`)
      if (res.data.status === 'ok') {
         dispatch({
            type: WALLET_BALANCE,
            payload: res.data.data,
         });
      } else {
         NotificationManager.error(res.data.msg)
      }
      dispatch(endLoading())
   } catch (e) {
      dispatch(endLoading())
   }
}


export const getRevenueBalance = () => async (dispatch) => {

   //change env
   const userInfo = configureStore.getState().auth.signup_info
   AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
   dispatch(startLoading())
   try {
      const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/revenue-splits?component=balance&disbursement=0`)
      if (res.data.status === 'ok') {
         dispatch({
            type: REVENUE_BALANCE,
            payload: res.data.data,
         });
      } else {
         NotificationManager.error(res.data.msg)
      }
      dispatch(endLoading())
   } catch (e) {
      dispatch(endLoading())
   }
}

export const getRevenueDaily = (page = 1, start_date = '', end_date = '') => async (dispatch) => {

   //change env
   const userInfo = configureStore.getState().auth.signup_info
   AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
   dispatch(startLoading())
   try {
      const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/revenue-splits?item_per_page=20&page=${page}&start_date=${start_date}&end_date=${end_date}`)
      if (res.data.status === 'ok') {
         dispatch({
            type: REVENUE_DAILY,
            payload: res.data.data,
         });
      } else {
         NotificationManager.error(res.data.msg)
      }
      dispatch(endLoading())
   } catch (e) {
      dispatch(endLoading())
   }
}



export const getRevenueDailyCount = (start_date = '', end_date = '') => async (dispatch) => {

   //change env
   const userInfo = configureStore.getState().auth.signup_info
   AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
   dispatch(startLoading())
   try {
      const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/revenue-splits?component=count&start_date=${start_date}&end_date=${end_date}`)
      if (res.data.status === 'ok') {
         dispatch({
            type: REVENUE_DAILY_COUNT,
            payload: res.data.data.total ? res.data.data.total : 0,
         });
      } else {
         NotificationManager.error(res.data.msg)
      }
      dispatch(endLoading())
   } catch (e) {
      dispatch(endLoading())
   }
}

export const getRevenueMonthly = () => async (dispatch) => {

   //change env
   const userInfo = configureStore.getState().auth.signup_info
   AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
   dispatch(startLoading())
   try {
      const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/revenue-splits?date_type=monthly`)
      if (res.data.status === 'ok') {
         dispatch({
            type: REVENUE_MONTHLY,
            payload: res.data.data,
         });
      } else {
         NotificationManager.error(res.data.msg)
      }
      dispatch(endLoading())
   } catch (e) {
      dispatch(endLoading())
   }
}


export const getCashOutDaily = (page = 1, start_date = '', end_date = '') => async (dispatch) => {

   //change env
   const userInfo = configureStore.getState().auth.signup_info
   AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
   dispatch(startLoading())
   try {
      const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/payout?item_per_page=20&page=${page}&start_date=${start_date}&end_date=${end_date}`)
      if (res.data.status === 'ok') {
         dispatch({
            type: CASHOUT,
            payload: res.data.data,
         });
      } else {
         NotificationManager.error(res.data.msg)
      }
      dispatch(endLoading())
   } catch (e) {
      dispatch(endLoading())
   }
}



export const getCashOutDailyCount = (start_date = '', end_date = '') => async (dispatch) => {

   //change env
   const userInfo = configureStore.getState().auth.signup_info
   AppEndpoint.configureEndpoint(userInfo?.last_name?.trim())
   dispatch(startLoading())
   try {
      const res = await axios.get(`${AppEndpoint.RevenueService}/revenues/payout?component=count&start_date=${start_date}&end_date=${end_date}`)
      if (res.data.status === 'ok') {
         dispatch({
            type: CASHOUT_COUNT,
            payload: res.data.data.total ? res.data.data.total : 0,
         });
      } else {
         NotificationManager.error(res.data.msg)
      }
      dispatch(endLoading())
   } catch (e) {
      dispatch(endLoading())
   }
}


export const getWalletDva = async (callback) => {

   const res = await axios.get(`${AppEndpoint.WalletService}/payments/dva`).catch(e => ({ error: e }))
   callback(res?.data)
}

