import React, {useEffect, useState} from "react";
import '../App.css'
import Topbar from "../components/topbar/Topbar";
import Sidebar from "../components/sidebar/Sidebar";
import {Outlet} from "react-router-dom";
import Modal from 'react-modal';
import {addVehicleInterested, refreshUserDetails, retrieveUserDetails} from "../actions/authAction";
import {connect} from "react-redux";
import Lottie from "lottie-react";
import verification from "../assets/images/verification.json";

const customStyles = {
    content: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
    },
};

const customStyles2 = {
    content: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'black',
        opacity:  0.8
    },
};


const  DashboardLayout = ({ signup_info,  addVehicleInterested, retrieveUserDetails, loading, refreshUserDetails}) => {
    const [formData, setFormData] = useState({vehicle_interested: {unit: '', amount: '0'},   nin_id: ''})
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalIsOpen2, setIsOpen2] = React.useState(false);

    const isNumberOfVehicles = signup_info?.partner_data?.partner_status === 0 && signup_info?.partner_data?.vehicle_interested?.length === 0
    const filteredVehicles = signup_info?.partner_data?.vehicle_interested?.filter(x => x.status === 0)

    function openModal() {
        setIsOpen(true);
    }


    function closeModal() {
        setIsOpen(false);
    }

    function openModal2() {
        setIsOpen2(true);
    }


    function closeModal2() {
        setIsOpen2(false);
    }

    useEffect(() => {
       if(isNumberOfVehicles) {
           openModal()
       }
    },[])

    useEffect(() => {
        retrieveUserDetails(signup_info?.auth_id)
    }, [])

    const {vehicle_interested, nin_id} = formData

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });


    return (
        <>
            <div className='overflow-auto' style={{background: '#F9F9F9', height: '100vh'}}>
                {!isNumberOfVehicles && <Topbar />}
                <div className="container1">
                    <Sidebar />
                    <div className='p-2 w-100 appMargin overflow-scroll'>
                        {signup_info?.partner_data?.partner_status === 0 ?
                            <div>
                                   <Lottie style={{height: '60vh'}} animationData={verification} loop={true} />
                               <div style={{fontSize: '20px'}} className='d-flex flex-column align-items-center justify-content-center mt-3'>
                                   <div className='text-center'>Account is currently undergoing verification' refresh page to see new status</div>
                                   <button disabled={loading} style={{width: '200px'}} type="button" className="btn btn-primary mt-5 appBg-button" onClick={() => refreshUserDetails(signup_info?.auth_id, openModal2)}>Refresh</button>

                               </div>
                            </div> :
                            <Outlet />
                        }
                    </div>
                </div>
            </div>
            <div>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className='w-100 h-100'>
                        <div style={{minHeight: '90%', background: '#F9F9F9', color: '#747474'}} className='align-items-center justify-content-center d-flex p-3 p-md-5'>
                            <div className=' bg-white px-5 py-4 formContainer' style={{borderRadius: '10px'}}>
                                <div style={{color: '#474242', fontWeight: '600'}} className='mb-3'>Enter numbers of vehicle(s) you are interested in, to get your account verified</div>
                                <form onSubmit={(e) =>{
                                    e.preventDefault()
                                    addVehicleInterested(signup_info?.nin_id?.value ? {vehicle_interested} : formData, closeModal)
                                }}>
                                    <div className="form-group w-100 mr-1">
                                        <label style={{color: '#747474'}} className='mt-3 mb-1'>Number of vehicle(s) interested</label>
                                        <input type="number" className="form-control" value={vehicle_interested?.unit}  placeholder="Enter number of vehicles interested"  name='vehicle_interested' onChange={(e) => setFormData({...formData, vehicle_interested: {...formData.vehicle_interested, unit: e.target.value}})}  required />
                                    </div>
                                    {!signup_info?.nin_id?.value && <div className="form-group w-100 mr-1">
                                        <label style={{color: '#747474'}} className='mt-3 mb-1'>{signup_info?.partner_data?.account_type === 'organization' ? "Director's NIN" : 'NIN'} </label>
                                        <input type="text" className="form-control"  placeholder="NIN" value={nin_id}  name='nin_id' onChange={onChange} required />
                                    </div>}
                                    <button disabled={loading}  type="submit" className="btn btn-primary w-100 mt-5 appBg-button" >Submit</button>

                                </form>
                        </div>
                        </div>
                    </div>
                </Modal>

                {/*modal*/}
                <Modal
                    isOpen={modalIsOpen2}
                    onRequestClose={closeModal2}
                    style={customStyles2}
                    contentLabel="Example Modal"
                >
                    {filteredVehicles?.length > 0 &&
                        <div className='d-flex align-items-center justify-content-center modal fade"' style={{width: "100%", height: '100vh'}}>
                            <div className='bg-white cus-w'>
                                <button type="button" className="btn-close float-end p-3"  onClick={() => closeModal2()} />
                                <div className='p-5'>
                                    <div>
                                        You have been verified and now eligible to become a LagosRide partner, Your partnership allows you earn 20% of the net earnings on trips made with the vehicle(s) daily.
                                    </div>
                                    <div className='mt-1'>
                                        {/*Pay ₦{(setting?.com_driver_fee?.total && (setting?.com_driver_fee?.total * filteredVehicles[0].unit * 0.2).toLocaleString())} to the account details below;*/}
                                        Kindly make payment of the sum of ₦{filteredVehicles[0]?.amount?.toLocaleString()} for the investment on {filteredVehicles[0]?.unit} vehicle(s) to the account details below;
                                    </div>
                                    <div className='mt-1'>
                                        <span style={{fontWeight: '800'}}>Bank Name:</span>   {signup_info?.payment_data?.bank_name}
                                    </div>
                                    <div className='mt-1'>
                                        <span style={{fontWeight: '800'}}>Account Name:</span>   {signup_info?.payment_data?.account_name}
                                    </div>
                                    <div className='mt-1'>
                                        <span style={{fontWeight: '800'}}>Account Number:</span>   {signup_info?.payment_data?.account_number}
                                    </div>
                                    <div>After payment, the vehicle(s) will be assigned to driver(s) and you can start earning.</div>
                                </div>
                            </div>
                        </div>
                    }

                </Modal>
            </div>
        </>

    );
}

function mapDispatchToProps(dispatch) {
    return {
        addVehicleInterested: (data,handleHideModal) => dispatch(addVehicleInterested(data, handleHideModal)),
        retrieveUserDetails: (auth_id) => dispatch(retrieveUserDetails(auth_id)),
        refreshUserDetails: (auth_id, openVehicleModal) => dispatch(refreshUserDetails(auth_id, openVehicleModal)),

    };
}

const mapStateToProps = (state) => ({
    signup_info: state.auth.signup_info,
    loading: state.loading.loading,
    availableVehiclesCount: state.vehicles.availableVehiclesCount
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout)


