export const LOADING_START = "LOADING_START";
export const LOADING_END = "LOADING_END";
export const SIGNUP_INFO = 'SIGNUP_INFO'
export const AUTH = 'AUTH'
export const DRIVERS = 'DRIVERS'
export const DRIVERS_COUNT = 'DRIVERS_COUNT'
export const SETTING = 'SETTING'
export const DRIVER = 'DRIVER'
export const VEHICLES = 'VEHICLES'
export const VEHICLES_COUNT = 'VEHICLES_COUNT'
export const DRIVER_RATING = 'DRIVER_RATING'
export const DRIVERS_PAYOUT = 'DRIVERS_PAYOUT'
export const DRIVERS_PAYOUT_COUNT = 'DRIVERS_PAYOUT_COUNT'
export const AVAILABLE_VEHICLES_COUNT = 'AVAILABLE_VEHICLES_COUNT'
export const DRIVERS_INACTIVE_COUNT = 'DRIVERS_INACTIVE_COUNT'
export const WALLET_BALANCE = 'WALLET_BALANCE'
export const REVENUE = 'REVENUE'
export const REVENUE_BALANCE = 'REVENUE_BALANCE'
export const REVENUE_DAILY = 'REVENUE_DAILY'
export const REVENUE_MONTHLY = 'REVENUE_MONTHLY'
export const REVENUE_DAILY_COUNT = 'REVENUE_DAILY_COUNT'
export const FORGOT_PASSWORD_EMAIL = 'FORGOT_PASSWORD_EMAIL'
export const LOG_OUT = 'LOG_OUT'
export const EXIST = 'EXIST'
export const CASHOUT = 'CASHOUT'
export const CASHOUT_COUNT = 'CASHOUT_COUNT'
